import { gql } from '../codegen'

export const getScrapers = gql(`
  query getScrapers{
    scraperStatus{
              id
              name
              lastCorrectRun
              lastError
              lastFoundData
              image
              favicon
              activeSkillsFilter{
                id
                skill
              }
              skillFilterScraperName
        }
    }
`)

export const getSkillFilter = gql(`
  query getSkillFilter{
    skillFilter{
      id
      linkedin
      angel
      startup
      indeed
      skill
    }
  }
`)

export const getLogEntries = gql(`
  query getLogEntries (
    $logger: String
    $levelName: String
    $startDate: Date
    $endDate: Date
    ){
      logEntries(
        logger: $logger
        levelName: $levelName
        startDate: $startDate
        endDate: $endDate
        ){
          id
          logger
          createTime
          message
          level
          levelName
      }
  }
`)

export const getAvailableCeleryTasks = gql(`
    query getAvailableTasks {
        availableCeleryTasks
    }
`)

export const getAvailableCeleryTestTasks = gql(`
    query getAvailableCeleryTestTasks {
        availableCeleryTestTasks
    }
`)

export const getAvailableWoodpeckerFields = gql(`
  query getAvailableWoodpeckerFields {
    __type(name: "FieldToSearchBy") {
        enumValues {
            name
        }
    }
  }
`)
