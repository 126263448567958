import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from '../../utils/config'

import Reports from '../Reports'
import Scrapers from '../Scrapers'
import SearchByBranches from '../SearchByBranches'
import RejectedReports from '../RejectedReports'
import Stats from '../Stats'
import Navbar from './Navbar'
import Authorize from '../Auth/Authorize'
import Burger from './Burger'

import style from './PageWrapper.module.scss'
import { Layout } from 'antd';
const { Content } = Layout;

const PageWrapper: React.FC = () => {
  const [ collapsed, setCollapsed ] = useState(true);

  return (
    <Authorize>
      <div className={ [ style.pageWrapper, collapsed && style.collapsed ].join(' ') }>
        <div className={ style.navbar }>
          <Navbar
            collapsed={ collapsed }
          />
          <Burger handleClick={ (): void=>{
            setCollapsed((prev)=>!prev)
          } }/>
        </div>
        <Layout>
          <Content className={ style.siteLayout }>
            <Switch>
              <Route path={ routes.reports } exact
                component={ Reports } />
              <Route path={ routes.rejectedReports } exact
                component={ RejectedReports }/>
              <Route path={ routes.scrapers } exact
                component={ Scrapers } />
              <Route path={ routes.searchByBranches } exact
                component={ SearchByBranches } />
              <Route path={ routes.stats } exact
                component={ Stats } />
              <Route component={ (): JSX.Element => <Redirect to={ routes.reports } /> }/>
            </Switch>
          </Content>
        </Layout>
      </div>
    </Authorize>
  )
}

export default PageWrapper
