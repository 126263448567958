import React, { useRef, RefObject, useState, useEffect } from 'react';
import style from './ReportsFilters.module.scss'
import moment from 'moment'
import { Form, Button, Select, Tooltip, Input, DatePicker, Badge, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { SearchOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from '@apollo/client'
import { GetLinkedinReportFilterOptionsQuery,
  GetLinkedinReportFilterOptionsQueryVariables }
  from '../../../graphql/codegen/graphql'
import { getLinkedinReportFilterOptions } from '../../../graphql/queries/reports'
import { Store } from 'antd/lib/form/interface';
import Backdrop from './../../UI/Backdrop'
import { SET_REPORT_FILTERS } from '../../../graphql/local/mutations/reports'
import { GET_REPORT_FILTERS } from '../../../graphql/local/queries/reports';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../utils/config';

const { Option } = Select;

type filterQuery = GetLinkedinReportFilterOptionsQuery;
type filterQueryVar = GetLinkedinReportFilterOptionsQueryVariables;

type OptionType = {
  name: string;
  label: string;
  options: string[];
}
type Props = {
  visible: boolean;
  handleVisible: () => void;
}

const ReportsFilters: React.FC<Props> = ({
  visible, handleVisible,
}) => {
  const history = useHistory()
  const refForm = useRef() as RefObject<FormInstance>
  const { data: fetchedFilters } = useQuery(GET_REPORT_FILTERS)
  const [ filters, setFilters ] = useState<OptionType[]>([])
  const { loading: loadingPlatform, data: platform } =
          useQuery<filterQuery, filterQueryVar>(
              getLinkedinReportFilterOptions, { variables: { filterName: 'platform' } });
  const { loading: loadingStatus, data: status } =
          useQuery<filterQuery, filterQueryVar>(
              getLinkedinReportFilterOptions, { variables: { filterName: 'status' } });
  const { loading: loadingEmployees, data: employees } =
      useQuery<filterQuery, filterQueryVar>(
          getLinkedinReportFilterOptions, { variables: { filterName: 'employees' } });
  const { loading: loadingCategory, data: category } =
      useQuery<filterQuery, filterQueryVar>(
          getLinkedinReportFilterOptions, { variables: { filterName: 'category' } });
  const { loading: loadingLocation, data: location } =
      useQuery<filterQuery, filterQueryVar>(
          getLinkedinReportFilterOptions, { variables: { filterName: 'location' } });
  const { loading: loadingCountry, data: country } =
      useQuery<filterQuery, filterQueryVar>(
          getLinkedinReportFilterOptions, { variables: { filterName: 'country' } });

  const [ setReportFilters ] = useMutation(SET_REPORT_FILTERS)

  useEffect(()=> {
    if (!loadingPlatform && !loadingStatus && !loadingEmployees &&
      !loadingCategory && !loadingLocation && !loadingCountry ) {
      setFilters([
        {
          name: 'platform',
          label: 'Platform',
          options: platform?.linkedinReportFilterOptions as string[],
        }, {
          name: 'status',
          label: 'Status',
          options: status?.linkedinReportFilterOptions as string[],
        }, {
          name: 'employees',
          label: 'Employees',
          options: employees?.linkedinReportFilterOptions as string[],
        }, {
          name: 'category',
          label: 'Category',
          options: category?.linkedinReportFilterOptions as string[],
        }, {
          name: 'location',
          label: 'Location',
          options: location?.linkedinReportFilterOptions as string[],
        }, {
          name: 'country',
          label: 'Country',
          options: country?.linkedinReportFilterOptions as string[],
        },
      ])
    }
  }, [ loadingPlatform, loadingStatus, loadingEmployees,
    loadingCategory, loadingLocation, loadingCountry ])

  const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
  const activeFilters = fetchedFilters && Object.keys(fetchedFilters.reportFilters).filter((filterName) => (
    fetchedFilters.reportFilters[ filterName ] !== null &&
      !filterName.startsWith('__')
  ));

  const handleClearForm = (): void => {
    (refForm?.current as FormInstance).resetFields()
    setReportFilters({
      variables: {
        platform: null,
        startDate: null,
        endDate: null,
        status: null,
        employees: null,
        category: null,
        location: null,
        country: null,
        search: null,
        withoutEmail: null,
        ids: null,
      } })
    history.push(routes.reports)
  }

  const handleSubmit = (values: Store): void => {
    handleVisible()
    const startDate = values.startDate ?
      moment(values.startDate).format('YYYY-MM-DD') : null
    const endDate = values.endDate ?
      moment(values.endDate).format('YYYY-MM-DD') : null

    const selected = { ...values }
    for (const [ key, value ] of Object.entries(selected)) {
      !value && (selected[ key ] = null)
    }

    // eslint-disable-next-line max-len
    setReportFilters({ variables: { ...fetchedFilters?.reportFilters, ...selected, startDate, endDate, withoutEmail: values.withoutEmail } })
  }

  const getSelectItem = (filter: {name: string; label: string; options: string[]}): JSX.Element => (
    <Form.Item
      key={ filter.name }
      name={ filter.name }
      label={ filter.label }
      className={ style.selectFilter }
      colon={ false }>
      <Select
        placeholder={ filter.label }
        allowClear showSearch>
        {filter.options.map((option) => (
          <Option key={ option } value={ option }>{option}</Option>))}
      </Select>
    </Form.Item>)

  return (
    <Backdrop visible={ visible } handleVisible={ handleVisible }>
      <div
        onClick={ (e: React.MouseEvent<HTMLElement>): void => e.stopPropagation() }
        className={ [ style.filtersWrapper, !visible && style.hidden ].join(' ') }>
        <Form
          ref={ refForm } size='small' { ...layout }
          onFinish={ handleSubmit }>
          <Tooltip
            placement="topLeft"
            autoAdjustOverflow={ true }
            mouseEnterDelay={ 1 }
            overlayClassName={ style.antTooltip }
            title="Search by company name, url, email, job link and matched technologies">
            <Form.Item
              key={ 'search' }
              name={ 'search' }
              className={ style.searchWrapper }>
              <Input
                name={ 'search' }
                placeholder="Search"
                className={ style.search }
                allowClear
                addonAfter={ <SearchOutlined /> }
              />
            </Form.Item>
          </Tooltip>
          <div className={ style.filters }>

            <Form.Item
              key={ 'startDate' }
              name={ 'startDate' }
              label={ 'Start date' }
              className={ style.selectFilter }
              colon={ false }>
              <DatePicker showToday={ false }/>
            </Form.Item>
            <Form.Item
              key={ 'endDate' }
              name={ 'endDate' }
              label={ 'End Date' }
              className={ style.selectFilter }
              colon={ false }>
              <DatePicker/>
            </Form.Item>

            { filters.map((filter) => filter.options && getSelectItem(filter)) }

            <Form.Item
              key={ 'withoutEmail' }
              name={ 'withoutEmail' }
              label={ 'Without Email' }
              className={ style.selectFilter }
              valuePropName={ 'checked' }
              colon={ false }>
              <Checkbox />
            </Form.Item>
            <div className={ style.formButtons } >
              <Button type="primary" htmlType="submit" >
                Filter
              </Button>
              {fetchedFilters ?
                <Badge
                  count={ activeFilters.length }
                  title={ activeFilters.join(', ') }
                  color={ activeFilters.length ? 'blue' : undefined }>
                  <Button type='link' onClick={ handleClearForm }>
                    Clear
                  </Button>
                </Badge> : ''}
            </div>
          </div>
        </Form>
      </div>
    </Backdrop>
  )
}

export default ReportsFilters;
