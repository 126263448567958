import React from 'react';
import style from './AvailableTasks.module.scss';
import { useQuery, useMutation } from '@apollo/client';
import { Spin, Form, Select, Button, Space, notification } from 'antd';
import { getAvailableCeleryTasks, getAvailableCeleryTestTasks } from '../../../graphql/queries/scrapers';
import { runCeleryTask, runCeleryTestTask } from '../../../graphql/mutations/scrapers';
import {
  GetAvailableTasksQuery,
  GetAvailableCeleryTestTasksQuery,
  RunCeleryTask,
  RunCeleryTestTask,
  RootMutationRunCeleryTestTaskArgs,
} from '../../../graphql/codegen/graphql';
import { Store } from 'antd/lib/form/interface';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useForm } from 'antd/lib/form/Form';

const { Option } = Select;

type Props = {
    header: string;
    listOfTasks: Maybe<string>[];
    handleSubmit: (values: Store) => void;
}

const TaskSelector: React.FC<Props> = ({ header, listOfTasks, handleSubmit }) => {
  const [ form ] = useForm()
  return (
    <Form
      form={ form }
      onFinish={ (values) => {
        handleSubmit(values)
        form.resetFields()
      } }
    >
      <h4>{header}</h4>
      <Form.Item
        name={ 'taskName' }
      >
        <Select>
          {listOfTasks.map((v) => !v ? null : <Option key={ v } value={ v }>
            {v}
          </Option>)}
        </Select>
      </Form.Item>
      <Button htmlType={ 'submit' }>Execute</Button>
    </Form>
  )
}

const AvailableTasks: React.FC = () => {
  const { loading: isLoadingListOfTasks, data: listOfTasks } =
        useQuery<GetAvailableTasksQuery>(getAvailableCeleryTasks);

  const { loading: isLoadingListOfTestTasks, data: listOfTestTasks } =
        useQuery<GetAvailableCeleryTestTasksQuery>(getAvailableCeleryTestTasks);

  const [
    executeRunCeleryTask,
    { loading: isWaitingForTaskResponse },
  ] = useMutation<RunCeleryTask, RootMutationRunCeleryTestTaskArgs>(runCeleryTask);

  const [
    executeRunCeleryTestTask,
    { loading: isWaitingForTestTaskResponse },
  ] = useMutation<RunCeleryTestTask, RootMutationRunCeleryTestTaskArgs>(runCeleryTestTask);

  const handle = (msg: string): void => {
    notification.success({
      message: msg,
    });
  }
  const handleError = (msg: string): void => {
    notification.error({
      message: msg,
    });
  }

  const handleRunTask = (values: Store): void => {
    executeRunCeleryTask({
      variables: {
        taskName: values.taskName as Required<string>,
      },
    }).then(() => handle('Task executed successfully'))
        .catch((err) => handleError(err.message));
  }

  const handleRunTestTask = (values: Store): void => {
    executeRunCeleryTestTask({
      variables: {
        taskName: values.taskName as Required<string>,
      },
    }).then(() => handle('Test task executed successfully'))
        .catch((err) => handleError(err.message));
  }

  return (
    <Spin
      spinning={ isLoadingListOfTasks || isWaitingForTestTaskResponse || isWaitingForTaskResponse || isLoadingListOfTestTasks }
      tip={ 'Loading...' } delay={ 100 } size={ 'large' }
    >
      <section className={ style.scraperTasks }>
        <h3>Available tasks</h3>
        <Space size={ 40 } direction={ 'vertical' }>
          <TaskSelector
            handleSubmit={ handleRunTask }
            header={ 'Select task to execute' }
            listOfTasks={ listOfTasks?.availableCeleryTasks || [] }
          />
          <TaskSelector
            handleSubmit={ handleRunTestTask }
            header={ 'Select test task to execute (results won\'t save in database)' }
            listOfTasks={ listOfTestTasks?.availableCeleryTestTasks || [] }
          />
        </Space>
      </section>
    </Spin>
  )
}

export default AvailableTasks;
