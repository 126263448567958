import React, { useState, useEffect } from 'react'
import style from './ScraperLogs.module.scss'
import { useQuery } from '@apollo/client'
import { getLogEntries } from './../../../graphql/queries/scrapers'
import { GetLogEntriesQuery, GetLogEntriesQueryVariables, LogEntryType } from '../../../graphql/codegen/graphql'
import { formatDateFromStringLogs } from './../../../utils/helpers'
import { Spin, Tag } from 'antd'
import moment from 'moment';

type Props = {
  scraperName: string;
  shouldFetchMoreLogs: boolean;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  logLevelName: string | null;
}

const ScraperLogs: React.FC<Props> = ({ scraperName, shouldFetchMoreLogs, startDate, endDate, logLevelName }) => {
  const [ displayNumber, setDisplayNumber ] = useState(10)
  const [ filteredLogs, setFilteredLogs ] = useState<LogEntryType[]>([])

  const { data: logs, loading } = useQuery<
  GetLogEntriesQuery, GetLogEntriesQueryVariables>(
      getLogEntries, { variables: { logger: ('scraper.'+scraperName) } },
  )

  useEffect(()=> {
    if (shouldFetchMoreLogs) {
      setDisplayNumber((prev)=>prev+10)
    }
  }, [ shouldFetchMoreLogs ])

  useEffect(() => {
    if (logs?.logEntries == null) {
      setFilteredLogs([]);
      return;
    }

    let allLogs = logs.logEntries as LogEntryType[];

    if (startDate) {
      allLogs = allLogs.filter((v) => moment(v?.createTime).isAfter(startDate));
    }
    if (endDate) {
      allLogs = allLogs.filter((v) => moment(v?.createTime).isBefore(endDate));
    }
    if (logLevelName) {
      allLogs = allLogs.filter((v) => v?.levelName === logLevelName);
    }

    setDisplayNumber(10);
    setFilteredLogs(allLogs);
  }, [ logs, startDate, endDate, logLevelName ]);

  const getType= (name: string): JSX.Element => {
    switch (name) {
      case 'ERROR': return <Tag className={ [ style.tag, style.red ].join(' ') }>
        {name}</Tag>
      case 'WARNING': return <Tag className={ [ style.tag, style.yellow ].join(' ') }>
        {name}</Tag>
      default: return <Tag className={ style.tag }>
        {name}</Tag>
    }
  }

  return (
    <Spin
      spinning={ loading }
      tip="Loading..." delay={ 1000 } size="large">
      <div className={ style.logs } >
        {!loading && filteredLogs.slice(0, displayNumber).map((log) => (
          <div key={ log?.id } className={ style.record }>
            <div className={ style.row }>
              <span className={ style.label }>Type</span>
              <span className={ [ style.content, style.small ].join(' ') }>{getType(log?.levelName as string)}</span>
              <span className={ style.label }>Date</span>
              <span className={ [ style.content, style.small ].join(' ') }>{
             log?.message ? formatDateFromStringLogs(log?.createTime) : ''}</span>
            </div>
            <div className={ style.row }>
              <span className={ style.label }>Message</span>
              <span className={ [ style.content ].join(' ') }>{log?.message}</span>
            </div>
          </div>
        ))}
      </div>
    </Spin>
  )
}

export default ScraperLogs
