import { notification } from 'antd';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { localMutationResolvers } from './local/resolvers';
import { GET_REPORT_FILTERS, GET_SELECTED, GET_SORT_CONFIG } from './local/queries/reports';
import { localInitialState } from './local/initialState';
import apiUrl from '../utils/constant';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${ token }` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors.length === 0) {
      notification.error({ message: 'We\'re sorry, an error occured' });
    } else {
      if (
        graphQLErrors[ 0 ]?.message !== 'Please, enter valid credentials' &&
        !graphQLErrors[ 0 ].message.includes('duplicate key value violates unique constraint')
      ) {
        notification.error({
          message: 'We\'re sorry, there\'s problem with data collection :(',
        })
      }
    }
  }

  if (networkError) {
    notification.error({ message: 'Problem with network connection' });
  }
});

const httpLink = new HttpLink({
  uri: apiUrl + 'graphql/',
});

const link = ApolloLink.from([
  errorLink,
  authLink,
  httpLink,
]);

const cache = new InMemoryCache();
cache.writeQuery({
  query: GET_REPORT_FILTERS,
  data: localInitialState,
})
cache.writeQuery({
  query: GET_SORT_CONFIG,
  data: localInitialState,
})
cache.writeQuery({
  query: GET_SELECTED,
  data: localInitialState,
})

export const client = new ApolloClient({
  link: link,
  cache: cache,
  resolvers: {
    Mutation: {
      ...localMutationResolvers,
    },
  },
});
