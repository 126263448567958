import React, { useState, useEffect } from 'react';
import { ResponsiveLine, Serie } from '@nivo/line';
import { Card, CardContent, Paper } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import { ChartDataType } from '../types';
import { getColors } from './../../../utils/helpers';
import styles from './LinearChart.module.scss';

type Props = {
  data: ChartDataType[];
  header: string;
};

const LinearChart: React.FC<Props> = ({ data, header }) => {
  const [ colors, setColors ] = useState<string[]>([]);

  useEffect(()=> {
    data && setColors(getColors(data?.length));
  }, [ data ]);

  return (
    <Paper elevation={ 3 } classes={ { elevation3: styles.chartCard } }>
      <div className={ styles.header }>
        <span>{ header }</span>
        <div className={ styles.boxes }>
          {data?.map((item, index) => (
            <div
              key={ index }
              className={ styles.box }
              style={ { backgroundColor: colors[ index ] } }
            >
              <ShowChartIcon htmlColor='white'/>
              <div className={ styles.name }> { item.id }</div>
              <div className={ styles.number }>{item.sum > 999 ? '999+' : item.sum }</div>
            </div>
          ))}
        </div>
      </div>
      <div className={ styles.chart }>
        <ResponsiveLine
          data={ data as Serie[] }
          yScale={ {
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          } }

          axisTop={ null }
          axisRight={ null }
          axisBottom={ {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Month',
            legendOffset: 36,
            legendPosition: 'middle',
          } }
          axisLeft={ {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendOffset: -40,
            legendPosition: 'middle',
          } }

          margin={ {
            top: 40,
            right: 40,
            bottom: 70,
            left: 50,
          } }
          colors={ colors }

          pointSize={ 10 }
          pointColor={ '#ffffff' }
          pointBorderWidth={ 2 }
          pointBorderColor={ { from: 'serieColor' } }
          useMesh={ true }
          tooltip={ ({ point }): JSX.Element => (
            <Card >
              <CardContent style={ { height: '5px' } }>
                <span
                  className={ styles.dot }
                  style={ { backgroundColor: point.serieColor } }
                ></span>
                <span className={ `${ styles.tooltip } ` }>
                  <b>{ point.data.yFormatted }</b>
                  { ' scraped by ' }
                  <b>{point.serieId}</b>
                  { ' in ' }
                  <b>{ point.data.xFormatted }</b>
                </span>
              </CardContent>
            </Card>
          ) }

          legends={ [
            {
              anchor: 'bottom-right',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 70,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 100,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 15,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
            },
          ] }
        />
      </div>
    </Paper>
  );
};

export default LinearChart;
