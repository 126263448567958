import React, { useState, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { Form, Input, Button, Typography, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import style from './Login.module.scss'
import logo from './../../../assets/images/ProfilSoftware_logo.svg'
import { routes } from '../../../utils/config';
import { Store } from 'antd/lib/form/interface'
import apiUrl from '../../../utils/constant'

const { Text } = Typography;

const rules = {
  username: [ {
    required: true,
    message: 'Please input your Username!',
  } ],
  password: [ {
    required: true,
    message: 'Please input your Password!',
  } ],
}

type Props = RouteComponentProps;

const Login: React.FC<Props> = ({ history }): ReactElement => {
  const [ form ] = Form.useForm()
  const [ error, setError ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false)

  const clearForm = (): void => {
    form.resetFields();
  }

  const logIn = (token: string): void => {
    localStorage.setItem('token', token)
    history.push(routes.reports);
    setError('')
  }

  const handleSubmit = (values: Store): void => {
    setIsLoading(true);
    fetch( apiUrl +'login/',
        {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: values.username,
            password: values.password,
          }),
        })
        .then((response) => response.json())
        .then((result) => {
          if (!result.access) {
            notification.error({
              message: 'Wrong data',
            });
          } else {
            logIn(result.access)
          }
        }).finally(() => {
          setIsLoading(false)
          clearForm()
        })
  }

  return (
    <div className={ style.Login }>
      <div className={ style.card }>
        <div className={ style.header }>
          <img src={ logo } alt="Profil Software" />
        </div>

        <div className={ style.error }>
          <Text type="danger">{error}</Text>
        </div>
        <Form
          form={ form }
          name="normal_login"
          onFinish={ handleSubmit }
          size="large"
        >
          <Form.Item name="username" rules={ rules.username }>
            <Input
              allowClear
              prefix={ <UserOutlined /> }
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item name="password" rules={ rules.password } >
            <Input
              allowClear
              prefix={ <LockOutlined /> }
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={ style.loginFormButton } loading={ isLoading }>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>

    </div >
  )
}

export default Login
