import React, { useState, useEffect } from 'react';
import style from './ReportsHeader.module.scss';
import ReportsFilters from './../ReportsFilters';
import ParserSettings from './../ParserSettings';
import { useQuery } from '@apollo/client'
import {
  Button, Typography, Popconfirm, Tooltip, Badge } from 'antd';
import { FilterOutlined, DeleteOutlined, FileTextOutlined, CheckSquareOutlined, SettingOutlined,
} from '@ant-design/icons';
import { GET_SELECTED } from './../../../graphql/local/queries/reports'

const { Title } = Typography;

type Props = {
  totalCount: number;
  handleDelete: () => void;
  handleAccept: () => void;
  handleExport: () => void;
  handleSelectingReports: () => void;
};

const ReportsHeader: React.FC<Props> = ({
  totalCount,
  handleDelete,
  handleExport,
  handleAccept,
}) => {
  const { data: fetchedSelected } = useQuery(GET_SELECTED)
  const [ hasSelected, setHasSelected ] = useState(false)
  const [ filtersVisible, setFiltersVisible ] = useState(false);
  const [ settingsVisible, setSettingsVisible ] = useState(false);

  useEffect(()=> {
    setHasSelected(fetchedSelected?.selected?.length > 0)
  }, [ fetchedSelected ])

  const onConfirmDelete = (): void => {
    handleDelete();
  };

  const onConfirmAccept = (): void => {
    handleAccept();
  };

  return (
    <div className={ style.header }>
      <div className={ style.leftContainer }>
        <Tooltip
          placement="topLeft"
          autoAdjustOverflow={ true }
          mouseEnterDelay={ .6 }
          overlayClassName={ style.antTooltip }
          title="Delete">
          <Popconfirm
            disabled={ !hasSelected }
            title={ 'Delete ' + fetchedSelected?.selected?.length +(fetchedSelected?.selected?.length === 1 ? ' row?' : ' rows?' ) }
            onConfirm={ onConfirmDelete }
            okText="Delete"
            cancelText="Cancel"
            placement="bottomLeft"
            icon={ <DeleteOutlined /> }>
            <Button type="primary" danger disabled={ !hasSelected }>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Tooltip>
        <Tooltip
          placement="topLeft"
          autoAdjustOverflow={ true }
          mouseEnterDelay={ .6 }
          overlayClassName={ style.antTooltip }
          title="Export to csv">
          <Button type="primary" disabled={ !hasSelected } onClick={ handleExport }>
            <FileTextOutlined/>
          </Button>
        </Tooltip>
        <Tooltip
          placement="topLeft"
          autoAdjustOverflow={ true }
          mouseEnterDelay={ .6 }
          overlayClassName={ style.antTooltip }
          title="Select available reports to move them to accepted">
          <Popconfirm
            disabled={ !hasSelected }
            title={ 'Accept ' + fetchedSelected?.selected?.length +(fetchedSelected?.selected?.length === 1 ? ' row?' : ' rows?' ) }
            onConfirm={ onConfirmAccept }
            okText="Accept"
            cancelText="Cancel"
            placement="bottomLeft"
            icon={ <CheckSquareOutlined /> }>
            <Button type="primary" disabled={ !hasSelected }>
              <CheckSquareOutlined />
            </Button>
          </Popconfirm>
        </Tooltip>
      </div>
      <Title level={ 2 } className={ style.antTitle }>
        Rejected Reports
      </Title>
      <div className={ style.rightContainer }>
        <Badge count={ totalCount }
          offset={ [ -102, 36 ] }
          overflowCount={ 999 }
          showZero={ true }>
          <Button
            id="filters-button"
            type="primary"
            onClick={ (): void => setFiltersVisible((prev) => !prev) }>
            <FilterOutlined /> Filters
          </Button>
        </Badge>
        <Button
          id="settings-button"
          className={ style.settingsButton }
          type="primary"
          onClick={ (): void => setSettingsVisible(() => true) }>
          <SettingOutlined /> Settings
        </Button>
      </div>
      <ReportsFilters
        visible={ filtersVisible }
        handleVisible={ (): void => setFiltersVisible((prev) => !prev) } />
      <ParserSettings
        visible={ settingsVisible }
        setVisible={ setSettingsVisible }
      />
    </div>
  );
};

export default ReportsHeader;
