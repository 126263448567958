export const reportsInitialState = {
  reportFilters: {
    __typename: 'reportFilters',
    platform: null,
    startDate: null,
    endDate: null,
    status: null,
    employees: null,
    category: null,
    location: null,
    country: null,
    search: null,
    withoutEmail: null,
    ids: null,
  },
  sortConfig: {
    __typename: 'sortConfig',
    orderBy: 'date',
    orderDirection: 'desc',
  },
  selected: [],
}
