import { gql } from '../codegen'

export const changeReportStatus =
  gql(`
    mutation changeReportStatus($ids: [Int], $status: String!) {
        changeReportStatus(ids: $ids, status: $status) {
            name
            display
            reports {
                id
                jobLink
                date
                platform
                company {
                    id
                    url
                    name
                    employees
                    branches
                    site
                    location
                    cbRank
                }
                person {
                    id
                    href
                    name
                    linkedin
                    wantedSkills
                    linkedinJobTitle
                    location
                    country
                    countryState
                }
                matchedTechnologies
                email
                status
            }
        }
    }
`)

export const changeReportAcceptance =
  gql(`
    mutation changeReportAcceptance($ids: [Int]) {
        changeReportAcceptance(ids: $ids){
            reports
            reportIds
        }
    }
`)

export const deleteLinkedinReports =
  gql(`
    mutation deleteLinkedinReports($ids: [Int]) {
        deleteLinkedinReports(ids: $ids){
            deletedReports
            deletedReportIds
        }
    }
`)

export const fetchEmailsAndSendMessages =
gql(`
    mutation fetchEmailsAndSendMessages(
        $linkedinReportsIds: [Int]!, $woodpeckerCampaignId: Int!) {
            fetchEmailsAndSendMessages (
                linkedinReportsIds: $linkedinReportsIds, 
                woodpeckerCampaignId: $woodpeckerCampaignId) {
                    ok
                    reports {
                        id
                        jobLink
                        date
                        platform
                        company {
                            id
                            url
                            name
                            employees
                            branches
                            site
                            location
                            cbRank
                        }
                        person {
                            id
                            href
                            name
                            linkedin
                            wantedSkills
                            linkedinJobTitle
                            location
                            country
                            countryState
                        }
                        matchedTechnologies
                        email
                        status
                    }
                }
        }

`)
