/* eslint-disable react/display-name */
import React, { ReactText } from 'react';
import { Spin, Empty } from 'antd';
import { LinkedInReportType } from '../../../graphql/codegen/graphql'
import Report from './../Report'
import style from './ReportsTable.module.scss';
import InfiniteScroll from 'react-infinite-scroller';
import { useMutation } from '@apollo/client'
import { ADD_SELECTED, REMOVE_SELECTED } from '../../../graphql/local/mutations/reports'
import ReportsTableHeader from '../ReportsTableHeader';

type Props = {
  loading: boolean;
  reports: LinkedInReportType[];
  moreFetchPossible: boolean;
  fetchMoreReports: () => void;
  handleStatusChange: (ids: string[], status: string) => void ;
}

const ReportsTable: React.FC<Props> = ({
  loading, reports, moreFetchPossible,
  handleStatusChange, fetchMoreReports,
}) => {
  const [ addSelected ] = useMutation(ADD_SELECTED)
  const [ removeSelected ] = useMutation(REMOVE_SELECTED)

  const handleSelectChange = (add: boolean, id: ReactText ): void => {
    add ? addSelected({ variables: { key: id } }) : removeSelected({ variables: { key: id } })
  };
  return (
    <div className={ style.tableWrapper }>
      <InfiniteScroll
        loadMore={ fetchMoreReports }
        hasMore={ moreFetchPossible }
        useWindow={ false }
        initialLoad={ false }
        loader={ <Spin key={ 9999 } spinning={ loading } size="large"/> }
      >
        <table>
          <ReportsTableHeader
            rowsCount = { reports?.length }
            handleStatusChange={ handleStatusChange }
          />
          <tbody>
            {reports?.map((report, index) => (
              <Report
                key={ index }
                report={ report }
                handleStatusChange={ handleStatusChange }
                handleSelectChange={ handleSelectChange }
              />
            ))}
          </tbody>
        </table>
        { reports.length === 0 && !loading &&
        <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
        }
      </InfiniteScroll>
    </div>
  )
};

export default ReportsTable;
