import { gql } from '../codegen';

export const getParserSettings = gql(`
    query getParserSettings {
        parserSettings {
            id
            section
            name
            rawValue
            helpText
            fieldType
        }
    }
`);
