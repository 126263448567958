import { gql } from '../codegen'

export const login =
  gql(`
    mutation login($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        token
      }
    }
  `)

export const checkAuth =
  gql(`
    mutation checkAuth($token: String!) {
      verifyToken(token: $token) {
        payload
      }
    }
  `)
