/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { Checkbox, Badge } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import style from './ReportsTableHeader.module.scss';
import { useQuery, useMutation } from '@apollo/client'
import { GET_SELECTED, GET_SORT_CONFIG } from './../../../graphql/local/queries/reports'
import { SET_ALL_SELECTED, CLEAR_SELECTED, SET_SORT_CONFIG } from './../../../graphql/local/mutations/reports'

type CheckboxDisplay = {
  indeterminate: boolean;
  allChecked: boolean;
}

type Props = {
  rowsCount: number;
}

const ReportsTableHeader: React.FC<Props> = ({ rowsCount }) => {
  const { data: fetchedSelected } = useQuery(GET_SELECTED)
  const { data: fetchedSortConfig } = useQuery(GET_SORT_CONFIG)

  const [ setAllSelected ] = useMutation(SET_ALL_SELECTED)
  const [ clearSelected ] = useMutation(CLEAR_SELECTED)
  const [ setSortConfig ] = useMutation(SET_SORT_CONFIG)

  const [ checkboxDisplay, setCheckboxDisplay ] = useState<CheckboxDisplay>({
    indeterminate: false,
    allChecked: false,
  });

  useEffect(()=> {
    if (fetchedSelected?.selected.length === rowsCount && rowsCount !== 0) {
      setCheckboxDisplay({
        indeterminate: false,
        allChecked: true,
      })
    } else if (fetchedSelected?.selected.length === 0) {
      setCheckboxDisplay({
        indeterminate: false,
        allChecked: false,
      })
    } else {
      setCheckboxDisplay({
        indeterminate: true,
        allChecked: false,
      })
    }
  }, [ fetchedSelected?.selected, rowsCount ])

  const handleSelectAll = (e: CheckboxChangeEvent): void => {
    e.target.checked ? setAllSelected() : clearSelected()
  }

  const handleCaretClick = (orderBy: string): void => {
    const sortConfig =
      !fetchedSortConfig?.sortConfig?.orderDirection &&
        !fetchedSortConfig?.sortConfig?.orderBy ? { orderBy, orderDirection: null } :
      fetchedSortConfig?.sortConfig?.orderBy === orderBy &&
        !fetchedSortConfig?.sortConfig?.orderDirection ? { orderBy, orderDirection: 'desc' } :
      fetchedSortConfig?.sortConfig?.orderBy && fetchedSortConfig?.sortConfig?.orderBy !== orderBy ?
        { orderBy, orderDirection: null } :
      { orderBy: null, orderDirection: null }
    setSortConfig({ variables: { ...sortConfig } })
  }

  const getSorter = (field: string): JSX.Element => (
    <div className={ style.carets } onClick={ (): void => handleCaretClick(field) }>
      <CaretUpOutlined className={
        [ style.upCaret, fetchedSortConfig?.sortConfig?.orderBy === field &&
        !fetchedSortConfig?.sortConfig?.orderDirection ? style.active : '' ].join(' ') }/>
      <CaretDownOutlined className={
        [ style.downCaret, fetchedSortConfig?.sortConfig?.orderBy === field &&
        fetchedSortConfig?.sortConfig?.orderDirection ? style.active : '' ].join(' ') }/>
    </div>
  )

  const getSortTd = (size: string, column: string, title: string): JSX.Element => <th className={ style[ size ] }
    onClick={ (): void => handleCaretClick(column) }>
    { title }
    { getSorter(column) }
  </th>

  return <thead>
    <tr>
      <th className={ style.small } colSpan={ 2 }>
        <Badge count={ fetchedSelected?.selected.length }
          offset={ [ 9, -3 ] }
          overflowCount={ 999 }
          showZero={ true }
          className={ style.bagde }
        >
          <Checkbox
            indeterminate={ checkboxDisplay.indeterminate }
            onChange={ handleSelectAll }
            checked={ checkboxDisplay.allChecked }
          />
        </Badge>
      </th>
      { getSortTd('large', 'company__name', 'Company') }
      { getSortTd('large', 'person__name', 'Person') }
      { getSortTd('large', 'company__location', 'Location') }
      { getSortTd('large', 'person__linkedinJobTitle', 'Position') }
      { getSortTd('large', 'matchedTechnologies', 'Matched') }
      { getSortTd('medium', 'date', 'Date') }
      { getSortTd('medium', 'platform', 'Platform') }
    </tr>
  </thead>
};

export default ReportsTableHeader;
