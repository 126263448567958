import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';

import styles from './BarChart.module.scss';
import { getColors } from './../../../utils/helpers';
import { BarDataType } from '../types';

const chartColors = {
  lightBlue: '#a5b5d9',
  lightRed: '#e29dac',
  cyan: '#66ccc7',
  lightViolet: '#baa8d6',
  yellow: '#fcfc83',
  lightGreen: '#92de83',
  orange: '#ff8566',
};

type Props = {
  data: BarDataType[];
  header: string;
  keys: string[];
  indexBy: string;
};

const BarChart: React.FC<Props> = ({ data, header, indexBy, keys }) => {
  const [ colors, setColors ] = useState<string[]>([]);

  useEffect(()=> {
    data && setColors(getColors(keys.length, Object.values(chartColors)));
  }, [ data ]);

  return (
    <Paper elevation={ 3 } classes={ { elevation3: styles.barChart } }>
      <div className={ styles.header }>
        <span>{ header }</span>
      </div>
      <div className={ styles.chart }>
        <ResponsiveBar
          data={ data }
          keys={ keys }
          indexBy={ indexBy }

          margin={ { top: 50, right: 130, bottom: 100, left: 60 } }
          padding={ 0.3 }
          groupMode="grouped"
          indexScale={ { type: 'band', round: true } }
          colors={ colors }
          borderColor={ { from: 'color', modifiers: [ [ 'darker', 1.6 ] ] } }

          axisBottom={ {
            tickSize: 5,
            tickPadding: 5,
            legend: 'Scrapers',
            legendPosition: 'middle',
            legendOffset: 32,
          } }
          axisLeft={ {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40,
          } }

          labelTextColor={ { from: 'color', modifiers: [ [ 'darker', 1.6 ] ] } }
          legends={ [
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'row',
              justify: false,
              translateX: 41,
              translateY: 62,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ] }
          animate={ true }
        />
      </div>
    </Paper>
  );
};

export default BarChart;
