import { GET_REPORT_FILTERS, GET_SELECTED, GET_SORT_CONFIG } from './../queries/reports'
import { GET_REPORTS } from '../../queries/reports';

export const reportsMutations = {
  setReportFilters: (_root, variables, { cache }) => {
    cache.writeQuery({
      query: GET_REPORT_FILTERS,
      data: {
        reportFilters: { __typename: 'reportFilters', ...variables } } });
    return null;
  },
  setSortConfig: (_root, variables, { cache }) => {
    cache.writeQuery({
      query: GET_SORT_CONFIG,
      data: {
        sortConfig: { __typename: 'sortConfig', ...variables } } });
    return null;
  },
  setAllSelected: (_root, _variables, { cache }) => {
    const reports = cache.readQuery({ query: GET_REPORTS });
    cache.writeQuery({
      query: GET_SELECTED,
      data: {
        selected: reports.linkedinReports.linkedinReportDetails.
            map((report) => report.id),
      },
    });
    return null;
  },
  clearSelected: (_root, _variables, { cache }) => {
    cache.writeQuery({
      query: GET_SELECTED,
      data: {
        selected: [],
      },
    });
    return null;
  },
  addSelected: (_root, variables, { cache }) => {
    const keys = cache.readQuery({ query: GET_SELECTED });
    cache.writeQuery({
      query: GET_SELECTED,
      data: {
        selected: [ ...keys.selected, variables.key ],
      },
    });
    return null;
  },
  removeSelected: (_root, variables, { cache }) => {
    const keys = cache.readQuery({ query: GET_SELECTED });
    cache.writeQuery({
      query: GET_SELECTED,
      data: {
        selected: keys.selected.filter((key) => key !== variables.key),
      },
    });
    return null;
  },
}
