import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { routes } from './utils/config'

import PageWrapper from './components/PageWrapper'
import Login from './components/Auth/Login'
import 'antd/dist/antd.css';

import './App.scss'

const App: React.FC = () => <BrowserRouter basename='/'>
  <div className="App">
    <Switch>
      <Route path={ routes.login } exact component={ Login } />
      <Route path={ routes.root } component={ PageWrapper } />
    </Switch>
  </div>
</BrowserRouter>

export default App;
