import React, { useEffect } from 'react'
import ReportsTable from './ReportsTable'
import ReportsHeader from './ReportsHeader'
import { useQuery, useMutation } from '@apollo/client'
import { GetLinkedinReportsQuery, GetLinkedinReportsQueryVariables,
  LinkedInReportType, ChangeReportStatusMutationVariables, ChangeReportStatusMutation,
  DeleteLinkedinReportsMutation, DeleteLinkedinReportsMutationVariables,
  FetchEmailsAndSendMessagesMutation, FetchEmailsAndSendMessagesMutationVariables }
  from './../../graphql/codegen/graphql'
import { GET_REPORTS } from './../../graphql/queries/reports'
import { changeReportStatus, deleteLinkedinReports, fetchEmailsAndSendMessages }
  from './../../graphql/mutations/reports'
import { notification } from 'antd';
import { GET_REPORT_FILTERS, GET_SELECTED, GET_SORT_CONFIG,
} from './../../graphql/local/queries/reports'
import { CLEAR_SELECTED, ADD_SELECTED, SET_REPORT_FILTERS } from '../../graphql/local/mutations/reports'
import moment from 'moment';
import apiUrl from '../../utils/constant'

const Reports: React.FC = () => {
  const { data: fetchedSelected } = useQuery(GET_SELECTED)
  const { data: fetchedSortConfig } = useQuery(GET_SORT_CONFIG)
  const { data: fetchedFilters } = useQuery(GET_REPORT_FILTERS)
  const [ clearSelected ] = useMutation(CLEAR_SELECTED)
  const [ addSelected ] = useMutation(ADD_SELECTED)
  const [ changeReportStatusMutation, { loading: loadingMutation } ] = useMutation<
    ChangeReportStatusMutation, ChangeReportStatusMutationVariables >(changeReportStatus);

  const [ setReportFilters ] = useMutation(SET_REPORT_FILTERS)
  const queryParams = new URLSearchParams(window.location.search);
  const reportIds = queryParams.get('ids')?.split(',')?.map((id)=>parseInt(id));

  useEffect(() => {
    setReportFilters({ variables: {
      ids: reportIds,
    } })
  }, [])

  const { loading: loadingReports, data: fetchedReports, fetchMore, refetch } =
    useQuery<GetLinkedinReportsQuery, GetLinkedinReportsQueryVariables>(
        GET_REPORTS, { variables: {
          first: 20,
          ...fetchedSortConfig?.sortConfig,
          ...fetchedFilters?.reportFilters,
        },
        notifyOnNetworkStatusChange: true,
        },
    )

  const [ sendMailMutation, { loading: loadingSendMail } ] = useMutation<
    FetchEmailsAndSendMessagesMutation, FetchEmailsAndSendMessagesMutationVariables>(fetchEmailsAndSendMessages, {
      update(cache, { data }) {
        const updatedReports = data?.fetchEmailsAndSendMessages?.reports;
        data?.fetchEmailsAndSendMessages?.ok &&
        cache.writeQuery({
          query: GET_REPORTS,
          data: {
            ...fetchedReports,
            linkedinReports: {
              ...fetchedReports?.linkedinReports,
              linkedinReportDetails:
               [ ...(fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[]),
                 ...(updatedReports as LinkedInReportType[]) ],
            },
          },
        })
      },
    });

  const [ deleteLinkedinReportsMutation, { loading: loadingDeleteMutation } ] = useMutation<
    DeleteLinkedinReportsMutation, DeleteLinkedinReportsMutationVariables>(deleteLinkedinReports, {
      update(cache, { data }) {
        const deleted = data?.deleteLinkedinReports?.deletedReports;
        if (deleted && deleted > 0) {
          cache.writeQuery({
            query: GET_REPORTS,
            data: {
              ...fetchedReports,
              linkedinReports: {
                ...fetchedReports?.linkedinReports,
                linkedinReportDetails:
                  (fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[])
                      .filter((report) => !data?.deleteLinkedinReports?.deletedReportIds?.includes(+report.id)),
              },
            },
          })
        }
        clearSelected()
      },
    })

  useEffect(()=> {
    refetch({
      ...fetchedFilters?.reportFilters,
      ...fetchedSortConfig?.sortConfig,
    })
  }, [ fetchedFilters, fetchedSortConfig ])

  const fetchMoreReports = (): void => {
    fetchMore({ variables: {
      first: 20,
      skip: (fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[]).length,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      return {
        ...fetchMoreResult,
        linkedinReports: {
          ...fetchMoreResult.linkedinReports,
          linkedinReportDetails: [
            ...prev?.linkedinReports?.linkedinReportDetails as LinkedInReportType[],
            ...fetchMoreResult?.linkedinReports?.linkedinReportDetails as LinkedInReportType[],
          ],
        },
      }
    },
    })
  }

  const handleDelete = (): void => {
    deleteLinkedinReportsMutation({ variables: {
      ids: fetchedSelected?.selected.map((key: React.ReactText)=> +key) } })
        .then((res) => {
          const deleted = res?.data?.deleteLinkedinReports?.deletedReports;
          if (deleted && deleted > 0) {
            notification.success({ message: 'Reports deleted: ' + deleted + '!' });
          } else {
            notification.error({ message: 'No report deleted!' });
          }
        })
  }
  const handleSelectingReports = (): void => {
    const reports = fetchedReports ? fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[] : []
    clearSelected();
    reports.forEach((report) => {
      if (report.status === null && report.externalIntegrationsStatus !== 'WS' && moment(report.date).isSame(moment(), 'day')) {
        addSelected({ variables: { key: report.id } });
      }
    });
  }

  const handleExport = (): void => {
    const ids = fetchedSelected?.selected.join(',');
    window.location.href = apiUrl + 'export_csv/' + ids;
  }
  const handleNotExportedExport = (): void => {
    window.location.href = apiUrl + 'export_to_csv_not_exported_reports/';
  }
  const handleStatusChange = (ids: string[], status: string): void => {
    ids.length === 0 ?
      notification.error({ message: 'No reports selected' }) :
      changeReportStatusMutation({ variables: { ids: ids.map((id)=> +id), status } })
          .then((res) => {
            if (res?.data?.changeReportStatus?.reports?.length === 0) {
              notification.error({ message: 'No status changed' });
            } else {
              notification.success({ message: 'Status changed to ' +
                res?.data?.changeReportStatus?.display });
            }
          })
  }

  const handleSendMail = (woodpeckerCampaignId: number): void => {
    fetchedSelected?.selected.length === 0 ?
      notification.error({ message: 'No reports selected' }) :
      sendMailMutation({
        variables: {
          linkedinReportsIds: fetchedSelected?.selected.map((id: React.ReactText) => +id),
          woodpeckerCampaignId,
        },
      }).then((res) => {
        if (res?.data?.fetchEmailsAndSendMessages?.ok) {
          notification.success({ message: 'Mails are pending' })
        } else {
          notification.error({ message: 'No mail request sent' });
        }
      })
  }
  return <>
    <ReportsHeader
      handleDelete={ handleDelete }
      handleExport={ handleExport }
      handleNotExportedExport = { handleNotExportedExport }
      handleSendMail={ handleSendMail }
      handleSelectingReports={ handleSelectingReports }
      totalCount={ fetchedReports?.linkedinReports?.totalCount as number }
    />
    <ReportsTable
      reports={ fetchedReports ? fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[] : [] }
      loading={ loadingReports || loadingMutation || loadingDeleteMutation || loadingSendMail }
      moreFetchPossible={ !loadingReports &&
      (fetchedReports?.linkedinReports?.linkedinReportDetails?.length || 0) < (fetchedReports?.linkedinReports?.totalCount || 0) }
      fetchMoreReports={ fetchMoreReports }
      handleStatusChange= { handleStatusChange }/>
  </>
}

export default Reports
