import React from 'react';
import { useQuery } from '@apollo/client';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Spin } from 'antd';

import styles from './Stats.module.scss';
import Statuses from './Statuses/Statuses';
import Scraped from './Scraped/Scraped';
import BarStats from './BarStats/BarStats';
import { GetStatisticsFiltersQuery } from './../../graphql/codegen/graphql';
import { getStatisticsFilters } from '../../graphql/queries/statistics';

const Stats: React.FC = () => {
  const { loading, data } =
  useQuery<GetStatisticsFiltersQuery>(getStatisticsFilters);

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <Spin
        spinning={ loading }
        tip="Loading..."
        delay={ 500 }
        size="large"
      >
        { data && (
          <div className={ styles.stats }>
            <Scraped filters={ data }/>
            <Statuses filters={ data }/>
            <BarStats filters={ data }/>
          </div>
        )}
      </Spin>
    </LocalizationProvider>
  );
};

export default Stats;
