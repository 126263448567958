import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';

import Filters from '../Filters/Filters';
import LinearChart from '../LinearChart/LinearChart';
import { ChartDataType, StatisticsFiltersType, SubmitFiltersType } from '../types';
import {
  GetStatisticsFiltersQuery, GetStatisticsQuery, GetStatisticsQueryVariables,
} from '../../../graphql/codegen/graphql';
import { getStatistics } from '../../../graphql/queries/statistics';
import styles from './Statuses.module.scss';

type FiltersType = {
  frequency?: Array<string>;
  statuses?: Array<string>;
  scrapers?: Array<string>;
  tags?: Array<string>;
};

type Props = {
  filters: GetStatisticsFiltersQuery;
};

const Statuses: React.FC<Props> = ({ filters }) => {
  const defaultFilters = {
    startDate: moment().subtract(60, 'days'),
    endDate: moment(),
    frequency: filters.statisticsFilters?.frequency?.data?.[ 1 ],
    scrapers: [ filters.statisticsFilters?.scrapers?.data?.[ 0 ] ],
    statuses: filters.statisticsFilters?.statuses?.data,
  };
  const [ parsedData, setParsedData ] = useState<ChartDataType[]>([]);
  const [ parsedFilters, setParsedFilters ] = useState<StatisticsFiltersType>();
  const [ chosenFilters, setChosenFilters ] = useState<GetStatisticsQueryVariables>({
    ...defaultFilters,
    startDate: defaultFilters.startDate.format('YYYY-MM-DD'),
    endDate: defaultFilters.endDate.format('YYYY-MM-DD'),
  });

  const { data, loading } =
    useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(getStatistics, {
      variables: chosenFilters,
    });

  useEffect(()=> {
    setParsedFilters({
      frequency: {
        type: filters.statisticsFilters?.frequency?.type,
        data: filters.statisticsFilters?.frequency?.data,
        defaultValue: defaultFilters.frequency,
      },
      scrapers: {
        type: 'single',
        data: filters.statisticsFilters?.scrapers?.data,
        defaultValue: defaultFilters.scrapers,
      },
      statuses: {
        type: filters.statisticsFilters?.statuses?.type,
        data: filters.statisticsFilters?.statuses?.data,
        defaultValue: defaultFilters.statuses,
      },
    } as StatisticsFiltersType);
  }, [ filters ]);

  useEffect(()=> {
    if (data) {
      setParsedData(data?.statistics?.scrapped?.[ 0 ]?.statuses?.map((status)=> {
        return {
          id: status?.statusName,
          sum: status?.sum,
          data: status?.data?.map((item) => {
            return {
              x: item?.dateTime,
              y: item?.total,
            };
          }),
        };
      }) as ChartDataType[]);
    }
  }, [ data ]);

  const {
    control,
    handleSubmit,
  } = useForm<FiltersType>();

  const onSubmit = handleSubmit(({ frequency, scrapers, statuses, dates }: SubmitFiltersType) => {
    setChosenFilters({
      frequency,
      scrapersName: scrapers,
      statuses,
      startDate: dates[ 0 ].format('YYYY-MM-DD'),
      endDate: dates[ 1 ].format('YYYY-MM-DD'),
    });
  });

  return (
    <Spin
      spinning={ loading || !parsedFilters || !parsedData }
      tip="Loading..."
      delay={ 500 }
      size="large"
    >
      { parsedFilters && parsedData && (
        <div className={ styles.statsCard }>
          <Filters
            filters={ parsedFilters }
            defaultDates={ [ defaultFilters.startDate, defaultFilters.endDate ] }
            onSubmit={ onSubmit }
            control={ control }
          />
          <LinearChart
            header={ (chosenFilters.scrapersName || defaultFilters.scrapers?.[ 0 ]) + ' statuses' }
            data={ parsedData }
          />
        </div>
      )}
    </Spin>
  );
};

export default Statuses;
