import React, { useEffect } from 'react'
import ReportsTable from './ReportsTable'
import ReportsHeader from './ReportsHeader'
import { useQuery, useMutation } from '@apollo/client'
import { GetLinkedinReportsQuery, GetLinkedinReportsQueryVariables,
  LinkedInReportType, ChangeReportAcceptanceMutationVariables, ChangeReportAcceptanceMutation,
  DeleteLinkedinReportsMutation, DeleteLinkedinReportsMutationVariables }
  from './../../graphql/codegen/graphql'
import { GET_REPORTS } from './../../graphql/queries/reports'
import { changeReportAcceptance, deleteLinkedinReports }
  from './../../graphql/mutations/reports'
import { notification } from 'antd';
import { GET_REPORT_FILTERS, GET_SELECTED, GET_SORT_CONFIG,
} from './../../graphql/local/queries/reports'
import { CLEAR_SELECTED, ADD_SELECTED, SET_REPORT_FILTERS } from '../../graphql/local/mutations/reports'
import moment from 'moment';
import apiUrl from '../../../src/utils/constant'

const Reports: React.FC = () => {
  const { data: fetchedSelected } = useQuery(GET_SELECTED)
  const { data: fetchedSortConfig } = useQuery(GET_SORT_CONFIG)
  const { data: fetchedFilters } = useQuery(GET_REPORT_FILTERS)
  const [ clearSelected ] = useMutation(CLEAR_SELECTED)
  const [ addSelected ] = useMutation(ADD_SELECTED)
  const [ setReportFilters ] = useMutation(SET_REPORT_FILTERS)
  const queryParams = new URLSearchParams(window.location.search);
  const reportIds = queryParams.get('ids')?.split(',')?.map((id)=>parseInt(id));

  useEffect(() => {
    setReportFilters({ variables: {
      ids: reportIds,
    } })
  }, [])

  const { loading: loadingReports, data: fetchedReports, fetchMore, refetch } =
    useQuery<GetLinkedinReportsQuery, GetLinkedinReportsQueryVariables>(
        GET_REPORTS, { variables: {
          first: 20,
          isAccepted: false,
          ...fetchedSortConfig?.sortConfig,
          ...fetchedFilters?.reportFilters,
        },
        notifyOnNetworkStatusChange: true,
        },
    )

  const [ deleteLinkedinReportsMutation, { loading: loadingDeleteMutation } ] = useMutation<
    DeleteLinkedinReportsMutation, DeleteLinkedinReportsMutationVariables>(deleteLinkedinReports, {
      update(cache, { data }) {
        const deleted = data?.deleteLinkedinReports?.deletedReports;
        if (deleted && deleted > 0) {
          cache.writeQuery({
            query: GET_REPORTS,
            data: {
              ...fetchedReports,
              linkedinReports: {
                ...fetchedReports?.linkedinReports,
                linkedinReportDetails:
                  (fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[])
                      .filter((report) => !data?.deleteLinkedinReports?.deletedReportIds?.includes(+report.id)),
              },
            },
          })
        }
        clearSelected()
      },
    })

  const [ changeReportAcceptanceMutation, { loading: loadingMutation } ] = useMutation<
    ChangeReportAcceptanceMutation, ChangeReportAcceptanceMutationVariables >(changeReportAcceptance, {
      update(cache, { data }) {
        const reportResults = data?.changeReportAcceptance?.reports;
        if (reportResults && reportResults > 0) {
          cache.writeQuery({
            query: GET_REPORTS,
            data: {
              ...fetchedReports,
              linkedinReports: {
                ...fetchedReports?.linkedinReports,
                linkedinReportDetails:
                  (fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[])
                      .filter((report) => !data?.changeReportAcceptance?.reportIds?.includes(+report.id)),
              },
            },
          })
        }
        clearSelected()
      },
    })

  useEffect(()=> {
    refetch({
      ...fetchedFilters?.reportFilters,
      ...fetchedSortConfig?.sortConfig,
    })
  }, [ fetchedFilters, fetchedSortConfig ])

  const fetchMoreReports = (): void => {
    fetchMore({ variables: {
      first: 20,
      isAccepted: false,
      skip: (fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[]).length,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      return {
        ...fetchMoreResult,
        linkedinReports: {
          ...fetchMoreResult.linkedinReports,
          linkedinReportDetails: [
            ...prev?.linkedinReports?.linkedinReportDetails as LinkedInReportType[],
            ...fetchMoreResult?.linkedinReports?.linkedinReportDetails as LinkedInReportType[],
          ],
        },
      }
    },
    })
  }

  const handleDelete = (): void => {
    deleteLinkedinReportsMutation({ variables: {
      ids: fetchedSelected?.selected.map((key: React.ReactText)=> +key) } })
        .then((res) => {
          const deleted = res?.data?.deleteLinkedinReports?.deletedReports;
          if (deleted && deleted > 0) {
            notification.success({ message: 'Reports deleted: ' + deleted + '!' });
          } else {
            notification.error({ message: 'No report deleted!' });
          }
        })
  }

  const handleSelectingReports = (): void => {
    const reports = fetchedReports ? fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[] : []
    clearSelected();
    reports.forEach((report) => {
      if (report.status === null && moment(report.date).isSame(moment(), 'day')) {
        addSelected({ variables: { key: report.id } });
      }
    });
  }

  const handleExport = (): void => {
    const ids = fetchedSelected?.selected.join(',');
    window.location.href = apiUrl + 'export_csv/' + ids + '/';
  }

  const handleAccept = (): void => {
    changeReportAcceptanceMutation({ variables: {
      ids: fetchedSelected?.selected.map((key: React.ReactText)=> +key) } })
        .then((res) => {
          const reports = res?.data?.changeReportAcceptance?.reports;
          if (reports && reports > 0) {
            notification.success({ message: 'Reports accepted: ' + reports + '!' });
          } else {
            notification.error({ message: 'No report accepted!' });
          }
        })
  }

  return <>
    <ReportsHeader
      handleDelete={ handleDelete }
      handleExport={ handleExport }
      handleAccept={ handleAccept }
      handleSelectingReports={ handleSelectingReports }
      totalCount={ fetchedReports?.linkedinReports?.totalCount as number }
    />
    <ReportsTable
      reports={ fetchedReports ? fetchedReports?.linkedinReports?.linkedinReportDetails as LinkedInReportType[] : [] }
      loading={ loadingReports || loadingMutation || loadingDeleteMutation }
      moreFetchPossible={ !loadingReports &&
      (fetchedReports?.linkedinReports?.linkedinReportDetails?.length || 0) < (fetchedReports?.linkedinReports?.totalCount || 0) }
      fetchMoreReports={ fetchMoreReports } />
  </>
}

export default Reports
