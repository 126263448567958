import React from 'react'
import { Card, Timeline } from 'antd'
import { dateByStringSorter, formatDateFromString } from '../../../utils/helpers'
import SkillFilters from './../SkillFilters'
import style from './Scraper.module.scss'
import { ScraperStatusType } from './../../../graphql/codegen/graphql'

type Props = {
  scraper: ScraperStatusType;
  refetchScrapers: () => void;
}

const Scraper: React.FC<Props> = ({ scraper, refetchScrapers }) => {
  const timelineData = [
    {
      date: scraper.lastCorrectRun ? scraper.lastCorrectRun : null,
      description: ' - Last successful run',
      color: 'green',
    }, {
      date: scraper.lastError ? scraper.lastError : null,
      description: ' - Last error',
      color: 'red',
    }, {
      date: scraper.lastFoundData ? scraper.lastFoundData : null,
      description: ' - Last found data',
      color: 'green',
    },
  ].sort(dateByStringSorter);

  const formatText = (text: string): string => {
    return text.replace('_', ' ');
  }

  const status = {
    name: timelineData[ 0 ].color === 'red' ? 'Not working!' : 'Working',
    color: timelineData[ 0 ].color,
  }

  return <div className={ style.scraper }>
    <Card
      className={ [ style.antCard, style[ status.color ] ].join(' ') }
      title={ <>{formatText(scraper.name)}</> }
      key={ scraper.id }
      extra={
        <span>Status: <strong
          className={ [ style.status, style[ status.color ] ].join(' ') }>{status.name}
        </strong></span>
      }
    >
      <div className={ style.image }>
        <img src={ scraper.favicon } alt="Logo"></img>
      </div>
      <div className={ style.timeline }>
        <Timeline mode="left">
          {
            timelineData.map((date) => {
              return (<Timeline.Item
                key={ date.description }
                className={ style[ 'timeline-item' ] }
                color={ date.color }
                label={ date.date ? formatDateFromString(date.date) : '' }
              >
                {date.description}
              </Timeline.Item>)
            })
          }
        </Timeline>
      </div>

      <SkillFilters
        scraper={ scraper }
        refetchScrapers={ refetchScrapers }
      />
    </Card>
  </div>
}

export default Scraper
