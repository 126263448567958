import React, { ReactElement } from 'react'
import style from './Burger.module.scss'

type Props = {
  handleClick: () => void;
}

const Burger: React.FC<Props> = ({ handleClick }): ReactElement =>
  <div
    className={ style.burger }
    onClick={ handleClick }>
    <div className={ style.burgerLine }></div>
    <div className={ style.burgerLine }></div>
    <div className={ style.burgerLine }></div>
  </div>

export default Burger
