import React, { useEffect, useState, ReactElement } from 'react'
import { RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/client'
import { checkAuth } from '../../../graphql/mutations/auth';
import { CheckAuthMutation, CheckAuthMutationVariables } from '../../../graphql/codegen/graphql'
import { client } from '../../../graphql/client'
import { routes } from '../../../utils/config'
import { withRouter } from 'react-router-dom'

type Props = RouteComponentProps & {
  children: JSX.Element;
};

const Authorize: React.FC<Props> = ({ history, children }): ReactElement => {
  const [ mutationLogin ] = useMutation<CheckAuthMutation, CheckAuthMutationVariables>(checkAuth)
  const [ isAuth, setIsAuth ] = useState<boolean>(false)
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (token) {
      mutationLogin({ variables: { token } })
          .then(() => {
            setIsAuth(true)
          })
          .catch(() => {
            client.resetStore();
            localStorage.removeItem('token')
            history.push(routes.login);
          })
    } else {
      history.push(routes.login);
    }
  }, [ token, history ])

  return isAuth ? children : <></>
}

export default withRouter(Authorize)
