import { gql } from '../codegen'

export const updateSkillFilter =
  gql(`
  mutation updateSkillFilter(
    $id: Int!,
    $angel: Boolean = false, 
    $linkedin: Boolean = false, 
    $indeed: Boolean = false, 
    $startup: Boolean = false, 
    $finnNo: Boolean = false, 
    $pythonOrg: Boolean = false, 
    $glassdoor: Boolean = false,
    $kode24: Boolean = false
    ) {
      updateSkillFilter(
        id: $id
        angel: $angel
        linkedin: $linkedin
        indeed: $indeed
        startup: $startup
        finnNo: $finnNo
        pythonOrg: $pythonOrg
        glassdoor: $glassdoor
        kode24: $kode24
      ) {
        ok
      }
  }
`)

export const createSkillFilter =
  gql(`
    mutation createSkillFilter(
      $skill: String!
      $angel: Boolean = false, 
      $linkedin: Boolean = false, 
      $indeed: Boolean = false, 
      $startup: Boolean = false, 
      $finnNo: Boolean = false, 
      $pythonOrg: Boolean = false, 
      $glassdoor: Boolean = false,
      $kode24: Boolean = false,
    ) {
        createSkillFilter(
          skill: $skill
          angel: $angel
          linkedin: $linkedin
          indeed: $indeed
          startup: $startup
          finnNo: $finnNo
          pythonOrg: $pythonOrg
          glassdoor: $glassdoor
          kode24: $kode24
          ){
          ok
        }
    }
`)

export const runCeleryTask =
gql(`
    mutation runCeleryTask(
        $taskName: String!,
    ) {
        runCeleryTask(taskName: $taskName){
            ok
        }
    }
`)

export const runCeleryTestTask =
gql(`
  mutation runCeleryTestTask(
    $taskName: String!,
  ) {
    runCeleryTestTask(taskName: $taskName){
      ok
    }
  }
`)

export const changeWoodpeckerProspects =
gql(`
  mutation changeWoodpeckerProspects(
    $fieldToSearchBy: FieldToSearchBy!, 
    $valueToSearchBy: String!,
    $updateValues:FieldUpdateInput!
   ) {
    changeWoodpeckerProspects(
      fieldToSearchBy: $fieldToSearchBy, 
      valueToSearchBy: $valueToSearchBy, 
      updateValues: $updateValues) {
        ok
    }
  }
`)

export const sendVerificationCode =
gql(`
  mutation sendVerificationCode(
      $verificationCode: String!
  ) {
   sendVerificationCode(
   verificationCode:  $verificationCode
   ) {
      ok
   }
  }
`)

export const searchIndustriesByBranches =
gql(`
  mutation searchIndustriesByBranches(
    $branch: String!
    $location: String
    $email: String!
    $tag: String
    $platform: [String]
  ) {
  searchIndustriesByBranches(
  branch: $branch,
  location: $location,
  email: $email,
  tag: $tag,
  platform: $platform,
  ) { ok
 }
}
`)
