import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';

import Filters from '../Filters/Filters';
import BarChart from '../BarChart/BarChart';
import { StatisticsFiltersType, SubmitFiltersType, BarDataType } from '../types';
import {
  GetStatisticsFiltersQuery, GetStatisticsQuery, GetStatisticsQueryVariables,
} from '../../../graphql/codegen/graphql';
import { getStatistics } from '../../../graphql/queries/statistics';
import styles from './BarStats.module.scss';

type FiltersType = {
  tags: Array<string>;
  scrapers: Array<string>;
};

type Props = {
  filters: GetStatisticsFiltersQuery;
};

const BarStats: React.FC<Props> = ({ filters }) => {
  const defaultFilters = {
    startDate: moment().subtract(60, 'days'),
    endDate: moment(),
    scrapers: filters.statisticsFilters?.scrapers?.data,
    tags: [ 'python', 'django', 'javascript', 'React.js' ],
  };
  const [ parsedData, setParsedData ] = useState<BarDataType[]>();
  const [ keys, setKeys ] = useState<string[]>([]);
  const [ parsedFilters, setParsedFilters ] = useState<StatisticsFiltersType>();
  const [ chosenFilters, setChosenFilters ] = useState<GetStatisticsQueryVariables>({
    ...defaultFilters,
    startDate: defaultFilters.startDate.format('YYYY-MM-DD'),
    endDate: defaultFilters.endDate.format('YYYY-MM-DD'),
  });

  const { data, loading } =
  useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(getStatistics, {
    variables: chosenFilters,
  });

  useEffect(()=> {
    setParsedFilters({
      scrapers: {
        type: filters.statisticsFilters?.scrapers?.type,
        data: filters.statisticsFilters?.scrapers?.data,
        defaultValue: defaultFilters.scrapers,
      },
      tags: {
        type: filters.statisticsFilters?.tags?.type,
        data: filters.statisticsFilters?.tags?.data,
        defaultValue: defaultFilters.tags,
      },
    } as StatisticsFiltersType);
  }, [ filters ]);

  useEffect(()=> {
    if (data) {
      setParsedData(data?.statistics?.scrapped?.map((scraper)=> {
        const tagsEntries = scraper?.tags?.map((item) => [ item?.tagName || 'none', item?.sum ]);
        return {
          scraper: scraper?.scraperName,
          ...Object.fromEntries(tagsEntries || []),
        };
      }) as BarDataType[]);
      setKeys(data?.statistics?.scrapped?.[ 0 ]?.tags?.map((item) => item?.tagName || 'none') || []);
    }
  }, [ data ]);

  const {
    control,
    handleSubmit,
  } = useForm<FiltersType>();

  const onSubmit = handleSubmit(({ scrapers, tags, dates }: SubmitFiltersType) => {
    setChosenFilters({
      scrapersName: scrapers,
      tags,
      startDate: dates[ 0 ].format('YYYY-MM-DD'),
      endDate: dates[ 1 ].format('YYYY-MM-DD'),
    });
  });

  return (
    <Spin
      spinning={ loading || !parsedFilters || !parsedData }
      tip="Loading..."
      delay={ 500 }
      size="large"
    >
      { parsedFilters && parsedData && (
        <div className={ styles.statsCard }>
          <Filters
            filters={ parsedFilters }
            defaultDates={ [ defaultFilters.startDate, defaultFilters.endDate ] }
            onSubmit={ onSubmit }
            control={ control }
          />
          <BarChart header="Scraped tags" keys={ keys } indexBy="scraper" data={ parsedData }/>
        </div>
      )}
    </Spin>
  );
};

export default BarStats;
