import React, { FormEvent, useState } from 'react';
import styles from './VerificationCodeInput.module.scss';
import { Button, Input, notification, Space, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { SendVerificationCode, SendVerificationCodeMutationVariables } from '../../../graphql/codegen/graphql';
import { sendVerificationCode } from '../../../graphql/mutations/scrapers';

const VerificationCodeInput: React.FC = () => {
  const [ input, setInput ] = useState<string>('')

  const [
    executeSendVerificationCode,
    { loading: isWaitingForResponse },
  ] = useMutation<SendVerificationCode, SendVerificationCodeMutationVariables>(sendVerificationCode);

  const handle = (msg: string): void => {
    notification.success({
      message: msg,
    });
  }

  const handleError = (msg: string): void => {
    notification.error({
      message: msg,
    });
  }

  const handleSendingCode = (): void => {
    executeSendVerificationCode({
      variables: {
        verificationCode: input as Required<string>,
      },
    }).then(() => handle('Sent successfully'))
        .catch((err) => handleError(err.message));
  }
  return (
    <div className={ styles.VerificationCodeInput }>
      <Spin
        spinning={ isWaitingForResponse }
        tip={ 'Loading...' } delay={ 100 } size={ 'large' }
      >
        <section>
          <h3>LinkedIn verification code</h3>
          <h4>Insert here LinkedIn verification code sent to email used in ProspectIn</h4>
          <Space size={ 25 } direction={ 'vertical' }>

            <Input
              value={ input }
              onInput={ (e: FormEvent<HTMLInputElement>): void => setInput((e.target as HTMLInputElement).value) }
              name={ 'code' }
              placeholder="Verification code"
              allowClear
            />
            <Button
              htmlType="submit" onClick={
                (event): void => {
                  event.preventDefault()
                  handleSendingCode()
                }
              }>
              Send
            </Button>
          </Space>
        </section>
      </Spin>
    </div>
  )
};

export default VerificationCodeInput;
