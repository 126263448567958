import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import { Form, Modal, notification, Spin } from 'antd';
import { getParserSettings } from '../../../graphql/queries/parserSettings';
import { FormInstance } from 'antd/lib/form/Form';
import {
  GetParserSettingsQuery,
  GetParserSettingsQueryVariables,
  RootMutationUpdateParserSettingsArgs,
  GlobalPreferenceModelMutation,
  GlobalPreferenceModelType,
  GlobalPreferencesModelInput,
} from '../../../graphql/codegen/graphql';
import { updateParserSettings } from '../../../graphql/mutations/parserSettings';
import ScraperSettingField from './ScraperSettingField';
import CampaignSelect from '../ReportsHeader/CampaignSelect';
import style from './ParserSettings.module.scss';

type Props = {
    visible: boolean;
    setVisible: (prev: boolean) => void;
}
interface SettingSection {
  [name: string]: GlobalPreferenceModelType[];
}

const ScraperSettings: React.FC<Props> = ({ visible, setVisible }) => {
  const { loading: loadingQuery, data: parserSettings } =
        useQuery<GetParserSettingsQuery, GetParserSettingsQueryVariables>(getParserSettings);
  const [ parserSettingsBySection, setParserSettingsBySection ] = useState<SettingSection>({} as SettingSection);
  const [ updateSettings, { loading: loadingMutation } ] = useMutation<
      GlobalPreferenceModelMutation, RootMutationUpdateParserSettingsArgs>(updateParserSettings);
  const [ loadingProspects, setLoadingProspects ] = useState(false);
  const refForm = useRef() as RefObject<FormInstance>
  const hideModal = (): void => {
    setVisible(false);
  };
  const saveSettings = (): void => {
    refForm?.current?.validateFields().then(() => {
      const payload: GlobalPreferencesModelInput[] = [];
      parserSettings?.parserSettings?.forEach((setting) => {
        const newValue = refForm.current?.getFieldValue(setting?.name || '');
        payload.push({
          id: parseInt(setting?.id || '0'),
          newValue: newValue.toString(),
        });
      });
      updateSettings({ variables: {
        settings: payload,
      } }).then(() => {
        notification.success({ message: 'Scraper settings updated' });
        hideModal();
      })
    }).catch(() => {
      notification.error({ message: 'Invalid data' });
    })
  }
  useEffect(() => {
    const groupedSettings = {} as SettingSection;
    if (!parserSettings) {
      return;
    }
    parserSettings?.parserSettings?.forEach((setting) => {
      const sectionKey = setting?.section || 'Other';
      if (setting) {
        const arr = groupedSettings[ sectionKey ] || []
        arr.push(setting);
        groupedSettings[ sectionKey ] = arr;
      }
    })
    setParserSettingsBySection(groupedSettings);
  }, [ parserSettings ])

  const handleCustomFields = (setting: GlobalPreferenceModelType): JSX.Element => {
    let customElement = null;
    if (setting.name === 'default_woodpecker_campaign') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function, max-len
      customElement = <CampaignSelect onChange={ () => {} } setLoading={ setLoadingProspects } selectedCampaignId={ parseInt(setting?.rawValue || '0') } />
    }

    return <ScraperSettingField key={ setting.id } setting={ setting } customElement={ customElement } />
  }

  return (
    <div>
      <Spin spinning={ loadingQuery || loadingMutation || loadingProspects } tip="Loading..." delay={ 1000 }>
        <Modal
          title="Scraper settings"
          visible={ visible }
          onOk={ saveSettings }
          onCancel={ hideModal }
          okText="Save settings"
          cancelText="Close"
          width={ 1000 }
          className={ style.modalSettings }
        >
          <Form ref={ refForm }>
            { Object.keys(parserSettingsBySection).map((sectionName) => {
              return (
                <div key={ sectionName }>
                  <h2>{ sectionName }</h2>
                  {/* eslint-disable-next-line max-len */}
                  {parserSettingsBySection[ sectionName ].map((setting) => handleCustomFields(setting))}
                </div>
              )
            }) }
          </Form>
        </Modal>
      </Spin>
    </div>
  )
}

export default ScraperSettings;
