import React from 'react';
import style from '../Scrapers/AvailableTasks/AvailableTasks.module.scss';
import { Input, Space, Form, Button, notification, Checkbox } from 'antd';
import { useMutation } from '@apollo/client';
import {
  SearchIndustriesByBranches,
  SearchIndustriesByBranchesMutationVariables,
} from '../../graphql/codegen/graphql';
import { searchIndustriesByBranches } from '../../graphql/mutations/scrapers';

const SearchCompanies: React.FC = () => {
  const [ form ] = Form.useForm();
  const [ executeSearch ] = useMutation<
    SearchIndustriesByBranches,
    SearchIndustriesByBranchesMutationVariables
  >(searchIndustriesByBranches);

  const options = [
    { label: 'Angel', value: 'angel' },
    { label: 'Linkedin', value: 'linkedin' },
    { label: 'Startup', value: 'startup' },
  ];

  const handleRunTask = (values: SearchIndustriesByBranchesMutationVariables): void => {
    executeSearch({ variables: { ...values } })
        .then(() => {
          notification.success({ message: 'Task is running!' });
          form.resetFields()
        })
        .catch((err) =>
          notification.error({
            message: err.message,
          }),
        );
  };

  return (
    <div style={ { marginLeft: '2.5em' } }>
      <section className={ style.scraperTasks }>
        <h3> Search people by branches</h3>
        <Form form={ form } name="search_by_branches" onFinish={ handleRunTask }>
          <Space size={ 10 } direction={ 'vertical' }>
            <Form.Item name="platform">
              <Checkbox.Group options={ options } defaultValue={ [ 'Linkedin' ] } />
            </Form.Item>
            <Form.Item name="branch">
              <Input placeholder="Industry" />
            </Form.Item>
            <Form.Item name="tag">
              <Input allowClear placeholder="Tag" />
            </Form.Item>
            <Form.Item name="location">
              <Input allowClear placeholder="Location" />
            </Form.Item>
            <Form.Item name="email">
              <Input placeholder="Email" />
            </Form.Item>
            <Button type="primary" onClick={ form.submit }>
              Run Task
            </Button>
          </Space>
        </Form>
      </section>
    </div>
  );
};

export default SearchCompanies;
