import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client'
import { getWoodpeckerCampaignList } from '../../../graphql/queries/reports'
import { GetWoodpeckerCampaignListQuery, GetWoodpeckerCampaignListQueryVariables } from '../../../graphql/codegen/graphql'
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';

const { Option } = Select;

type Props = {
    onChange: (value: SelectValue) => void;
    setLoading: null | ((loading: boolean) => void);
    selectedCampaignId?: number;
};

const CampaignSelect: React.FC<Props> = ({
  onChange, setLoading, selectedCampaignId,
}) => {
  const { loading: loadingCampaigns, data: campaigns } = useQuery<
        GetWoodpeckerCampaignListQuery, GetWoodpeckerCampaignListQueryVariables>(
            getWoodpeckerCampaignList,
        )
  useEffect(() => {
    if (setLoading) {
      setLoading(loadingCampaigns)
    }
  }, [ loadingCampaigns ])

  return (
    <Select
      placeholder={ 'Woodpecker Campaign' }

      defaultValue={ selectedCampaignId }
      onChange={ onChange }>
      {campaigns?.woodpeckerCampaignList?.map((option) => (
        <Option
          key={ option?.name as string }
          value={ option?.id as number }>
          {option?.name}
        </Option>))}
    </Select>
  );
};

export default CampaignSelect;
