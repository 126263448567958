import { gql } from '@apollo/client';

export const SET_REPORT_FILTERS = gql`
  mutation SetReportFilters(
      $platform: String = null
      $startDate: String = null
      $endDate: String = null
      $status: String = null
      $employees: String = null
      $category: String = null
      $location: String = null
      $country: String = null
      $search: String = null
      $withoutEmail: Boolean = null
      $ids: [Int] = null
    ){
    setReportFilters(
      platform: $platform
      startDate: $startDate
      endDate: $endDate
      status: $status
      employees: $employees
      category: $category
      location: $location
      country: $country
      country: $country
      search: $search
      withoutEmail: $withoutEmail
      ids: $ids
    ) @client
  }
`;

export const SET_SORT_CONFIG = gql`
mutation SetSortConfig (
  $orderBy: String = null
  $orderDirection: String = null
  ) {
    setSortConfig (
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) @client
  }
`

export const SET_ALL_SELECTED = gql`
mutation SetAllSelected{
    setAllSelected @client
  }
`

export const CLEAR_SELECTED = gql`
mutation ClearSelected{
    clearSelected @client
  }
`

export const ADD_SELECTED = gql`
mutation AddSelected(
  $key: number) {
    addSelected(
      key: $key
    ) @client
  }
`
export const REMOVE_SELECTED = gql`
mutation RemoveSelected(
  $key: number) {
    removeSelected(
      key: $key
    ) @client
  }
`
