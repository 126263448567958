import React from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from '../../../utils/config'
import { logout } from '../../../utils/auth'
import 'antd/dist/antd.css';
import style from './Navbar.module.scss'
import Logo from '../Logo'
import { Menu } from 'antd';
import {
  ProfileOutlined, LineChartOutlined,
  LogoutOutlined, AlignLeftOutlined, FileSearchOutlined,
} from '@ant-design/icons';

type Props = {
  collapsed: boolean;
}

type NavigationType = {
  path: string;
  description: string;
  icon: JSX.Element;
}

const Navbar: React.FC<Props>= (
    { collapsed },
) => {
  const navigation: NavigationType[] = [
    {
      path: routes.reports,
      description: 'Reports',
      icon: <ProfileOutlined className={ style.navbarIcon } />,
    }, {
      path: routes.scrapers,
      description: 'Scrapers',
      icon: <AlignLeftOutlined className={ style.navbarIcon } />,
    }, {
      path: routes.searchByBranches,
      description: 'Search people by branches',
      icon: <FileSearchOutlined className={ style.navbarIcon } />,

    }, {
      path: routes.stats,
      description: 'Stats',
      icon: <LineChartOutlined className={ style.navbarIcon } />,
    }, {
      path: routes.rejectedReports,
      description: 'Rejected Reports',
      icon: <ProfileOutlined className={ style.navbarIcon } />,
    },
  ];

  return (
    <Menu theme="dark"
      className={ style.menu }
      mode={ 'inline' }
      inlineCollapsed={ collapsed }
    >
      <Logo />
      {
        navigation.map((route, index) =>
          <Menu.Item key={ index } >
            <NavLink to={ route.path }>
              {route.icon}
              <span>{route.description}</span>
            </NavLink>
          </Menu.Item>)
      }

      <Menu.Item key="logout"
        className={ style.navLogout }
        onClick={ logout }>
        <NavLink to={ routes.login }>
          <LogoutOutlined
            className={ style.navbarIcon } />
          <span>Logout</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  )
}

export default Navbar
