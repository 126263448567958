import React, { ReactText, useState, useEffect } from 'react';
import style from './Report.module.scss'
import ReportDetails from './../ReportDetails'
import { LinkedInReportType } from '../../../graphql/codegen/graphql'
import { Tag, Checkbox } from 'antd';
import { formatDateFromString } from '../../../utils/helpers'
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useQuery } from '@apollo/client'
import { GET_SELECTED } from './../../../graphql/local/queries/reports'
type Props = {
  report: LinkedInReportType;
  handleSelectChange: (add: boolean, id: ReactText) => void;
}

const Report: React.FC<Props> = ({
  report,
  handleSelectChange,
}) => {
  const [ expanded, setExpanded ] = useState<boolean>(false);
  const [ selected, setSelected ] = useState<boolean>(false);
  const { data: fetchedSelected } = useQuery(GET_SELECTED)

  useEffect(()=> {
    setSelected(fetchedSelected?.selected.includes(report.id))
  }, [ fetchedSelected ])

  const getMatchedTechnologies= (): JSX.Element => {
    if (report?.matchedTechnologies?.length && report?.matchedTechnologies?.length >= 1 &&
      report?.matchedTechnologies[ 0 ] !== '') {
      return <>{report?.matchedTechnologies?.map((tag) => (
        <Tag className={ style.antTag } color="blue" key={ tag as string }>
          {tag}
        </Tag>))}</>
    } else {
      return <div className={ style.dash }></div>
    }
  }

  const getCheckbox = (): JSX.Element => (
    <div onClick={ (e): void => e.stopPropagation() }>
      <Checkbox
        checked={ selected }
        onChange={
          (e: CheckboxChangeEvent): void => {
            e.stopPropagation()
            handleSelectChange(e.target.checked, report.id)
          }
        }/>
    </div>
  )

  const getArrow = (): JSX.Element => expanded ?
    <UpOutlined onClick={ (e): void => {
      e.stopPropagation(); setExpanded(false)
    } }/> :
    <DownOutlined onClick={ (e): void => {
      e.stopPropagation(); setExpanded(true)
    } } />

  const getColorStyle = (): string => {
    const color = [ style.report ]
    return color.join(' ')
  }

  const getDate = (text: string): string | JSX.Element =>
      text ? formatDateFromString(text) :
      <div className={ style.dash }></div>

  const renderText = (text: ReactText): ReactText | JSX.Element =>
    text ? text : <div className={ style.dash }></div>

  return (<>
    <tr
      onClick={
        (e): void => {
          e.stopPropagation(); setExpanded((prev) => !prev)
        } }
      className={ getColorStyle() }>
      <td >
        { getCheckbox() }
      </td>
      <td className={ style.arrow }>
        {getArrow()}
      </td>
      <td >
        { renderText(report?.company?.name || '') }
      </td>
      <td >
        { renderText(report?.person?.name || '') }
      </td>
      <td >
        { renderText(report?.company?.location || '') }
      </td>
      <td >
        { renderText(report?.person?.linkedinJobTitle || '') }
      </td>
      <td id="matchedTechnologies">
        { getMatchedTechnologies() }
      </td>
      <td className={ style.medium }>
        { getDate(report?.date) }
      </td>
      <td>
        { renderText(report?.platform) }
      </td>
    </tr>
    <ReportDetails record={ report } expanded={ expanded }/>
  </>
  )
}

export default Report;
