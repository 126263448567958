import { gql } from '../codegen';

export const updateParserSettings =
gql(`
    mutation updateParserSettings($settings: [GlobalPreferencesModelInput]) {
        updateParserSettings(settings: $settings ){
            ok
        }
    }
`)
