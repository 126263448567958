import { gql } from '../codegen'

export const GET_REPORTS = gql(`
query getLinkedinReports(
    $first: Int, $skip: Int, $search: String, $orderBy: String, $orderDirection: String,
    $platform: String,
    $startDate: Date,
    $endDate: Date,
    $status: String,
    $linkedinJobTitle: String,
    $employees: String,
    $category: String,
    $location: String,
    $country: String,
    $countryState: String,
    $ids: [Int],
    $withoutEmail: Boolean,
    $isAccepted: Boolean
) {
    linkedinReports(
        first: $first, skip: $skip, orderBy: $orderBy, search: $search, orderDirection: $orderDirection,
        platform: $platform,
        startDate: $startDate,
        endDate: $endDate,
        status: $status,
        linkedinJobTitle: $linkedinJobTitle,
        employees: $employees,
        category: $category,
        location: $location,
        country: $country,
        countryState: $countryState,
        ids: $ids,
        withoutEmail: $withoutEmail,
        isAccepted: $isAccepted
        )
        @connection(key: "linkedinReports")
        {
            totalCount
            linkedinReportDetails{
                id
                jobLink
                date
                platform
                isAccepted
                rejectionReason
                company {
                    id
                    url
                    name
                    employees
                    branches
                    site
                    location
                    cbRank
                }
                person {
                    id
                    href
                    name
                    linkedin
                    wantedSkills
                    linkedinJobTitle
                    location
                    country
                    countryState
                }
                matchedTechnologies
                email
                status
                externalIntegrationsStatus
                exportedToCsv
                associatedcompanyprojectSet {
                    casestudy {
                        name
                        country
                        city
                        lat
                        lon
                    }
                    distance
                }
            }
        }
    }
`)

export const getLinkedinReportFilterOptions = gql(`
    query getLinkedinReportFilterOptions($filterName: String) {
        linkedinReportFilterOptions(filterName: $filterName) 
        }
`)

export const getLinkedinReportsCount = gql(`
      query getLinkedinReportsCount{
        linkedinReportsCount
      }
`)

export const getWoodpeckerCampaignList = gql(`
    query getWoodpeckerCampaignList {
        woodpeckerCampaignList{
        id
        name
    }
}
`)
