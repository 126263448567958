import React, { ReactText, useState, useEffect } from 'react';
import style from './Report.module.scss'
import ReportDetails from './../ReportDetails'
import { LinkedInReportType } from '../../../graphql/codegen/graphql'
import { Tag, Checkbox, Tooltip } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, CheckOutlined, StopOutlined } from '@ant-design/icons'
import { formatDateFromString } from '../../../utils/helpers'
import { DownOutlined, UpOutlined, CloseOutlined, HistoryOutlined, WarningOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useQuery } from '@apollo/client'
import { GET_SELECTED } from './../../../graphql/local/queries/reports'
type Props = {
  report: LinkedInReportType;
  handleStatusChange: (ids: string[], status: string) => void ;
  handleSelectChange: (add: boolean, id: ReactText) => void;
}

const Report: React.FC<Props> = ({
  report,
  handleStatusChange,
  handleSelectChange,
}) => {
  const [ expanded, setExpanded ] = useState<boolean>(false);
  const [ selected, setSelected ] = useState<boolean>(false);
  const { data: fetchedSelected } = useQuery(GET_SELECTED)

  useEffect(()=> {
    setSelected(fetchedSelected?.selected.includes(report.id))
  }, [ fetchedSelected ])

  const getStatus= (): JSX.Element => {
    switch (report.status) {
      case 'RE': return <Tag className={ [ style.tag, style.red ].join(' ') } key={ report.status as string }>
        REJECTED</Tag>
      case 'AP': return <Tag className={ [ style.tag, style.green ].join(' ') } key={ report.status as string }>
        APPLIED</Tag>
      case 'MB': return <Tag className={ [ style.tag, style.blue ].join(' ') } key={ report.status as string }>
        MAYBE</Tag>
      default: return <div className={ style.dash }></div>
    }
  }

  const getOptions = (id: string): JSX.Element => (
    <div className={ style.statusIcons }
      onClick={ (e): void => e.stopPropagation() }>
      <Tooltip
        placement="bottomRight" autoAdjustOverflow={ true }
        mouseEnterDelay={ 1 } overlayClassName={ style.antTooltip }
        title={ 'Applied' }>
        <CheckCircleOutlined
          className={ style.green }
          onClick={ (): void=> handleStatusChange( [ id ], 'AP') }/>
      </Tooltip>
      <Tooltip
        placement="bottomRight" autoAdjustOverflow={ true }
        mouseEnterDelay={ 1 } overlayClassName={ style.antTooltip }
        title={ 'Maybe' }>
        <QuestionCircleOutlined
          className={ style.blue }
          onClick={ (): void=> handleStatusChange( [ id ], 'MB') }/>
      </Tooltip>
      <Tooltip
        placement="bottomRight" autoAdjustOverflow={ true }
        mouseEnterDelay={ 1 } overlayClassName={ style.antTooltip }
        title={ 'Rejected' }>
        <CloseCircleOutlined
          className={ style.red }
          onClick={ (): void=> handleStatusChange( [ id ], 'RE') }/>
      </Tooltip>
    </div>
  )

  const getMatchedTechnologies= (): JSX.Element => {
    if (report?.matchedTechnologies?.length && report?.matchedTechnologies?.length >= 1 &&
      report?.matchedTechnologies[ 0 ] !== '') {
      return <>{report?.matchedTechnologies?.map((tag) => (
        <Tag className={ style.antTag } color="blue" key={ tag as string }>
          {tag}
        </Tag>))}</>
    } else {
      return <div className={ style.dash }></div>
    }
  }

  const getCheckbox = (): JSX.Element => (
    <div onClick={ (e): void => e.stopPropagation() }>
      <Checkbox
        checked={ selected }
        onChange={
          (e: CheckboxChangeEvent): void => {
            e.stopPropagation()
            handleSelectChange(e.target.checked, report.id)
          }
        }/>
    </div>
  )

  const getExported = (): JSX.Element => {
    return report.exportedToCsv ? <CheckOutlined /> : <div/>
  }

  const getMailStatus = (): JSX.Element => {
    switch (report.externalIntegrationsStatus) {
      case 'WS': return <Tooltip
        placement="bottomLeft" autoAdjustOverflow={ true }
        mouseEnterDelay={ .1 } overlayClassName={ style.antTooltip }
        title='Email sent'>
        <CheckOutlined />
      </Tooltip>
      case 'NWS': return <Tooltip
        placement="bottomLeft" autoAdjustOverflow={ true }
        mouseEnterDelay={ .5 } overlayClassName={ style.antTooltip }
        title='Email not sent'>
        <CloseOutlined />
      </Tooltip>
      case 'FTLF': return <Tooltip
        placement="bottomLeft" autoAdjustOverflow={ true }
        mouseEnterDelay={ .5 } overlayClassName={ style.antTooltip }
        title='Email found'>
        <HistoryOutlined />
      </Tooltip>
      case 'FTHN': return <Tooltip
        placement="bottomLeft" autoAdjustOverflow={ true }
        mouseEnterDelay={ .5 } overlayClassName={ style.antTooltip }
        title='Email not found'>
        <WarningOutlined />
      </Tooltip>
      case 'FTLL': return <Tooltip
        placement="bottomLeft" autoAdjustOverflow={ true }
        mouseEnterDelay={ .5 } overlayClassName={ style.antTooltip }
        title='Requests limit, no credits left'>
        <StopOutlined />
      </Tooltip>
      case 'WIEF': return <Tooltip
        placement="bottomLeft" autoAdjustOverflow={ true }
        mouseEnterDelay={ .5 } overlayClassName={ style.antTooltip }
        title='Invalid email format'>
        <QuestionCircleOutlined />
      </Tooltip>
      default: return <div/>
    }
  }

  const getArrow = (): JSX.Element => expanded ?
    <UpOutlined onClick={ (e): void => {
      e.stopPropagation(); setExpanded(false)
    } }/> :
    <DownOutlined onClick={ (e): void => {
      e.stopPropagation(); setExpanded(true)
    } } />

  const getColorStyle = (): string => {
    const color = [ style.report ]
    color.push(
        report.status === 'RE' ? style.red :
        report.status === 'AP' ? style.green :
        report.status === 'MB' ? style.blue : '',
    );
    expanded && color.push(style.expanded)
    return color.join(' ')
  }

  const getDate = (text: string): string | JSX.Element =>
      text ? formatDateFromString(text) :
      <div className={ style.dash }></div>

  const renderText = (text: ReactText): ReactText | JSX.Element =>
    text ? text : <div className={ style.dash }></div>

  return (<>
    <tr
      onClick={
        (e): void => {
          e.stopPropagation(); setExpanded((prev) => !prev)
        } }
      className={ getColorStyle() }>
      <td >
        { getCheckbox() }
      </td>
      <td className={ style.arrow }>
        {getArrow()}
      </td>
      <td className={ style.exported }>
        {getExported()}
      </td>
      <td className={ style.exported }>
        {getMailStatus()}
      </td>
      <td>
        { getStatus() }
      </td>
      <td>
        { getOptions(report.id) }
      </td>
      <td >
        { renderText(report?.company?.name || '') }
      </td>
      <td >
        { renderText(report?.person?.name || '') }
      </td>
      <td >
        { renderText(report?.company?.location || '') }
      </td>
      <td >
        { renderText(report?.person?.linkedinJobTitle || '') }
      </td>
      <td id="matchedTechnologies">
        { getMatchedTechnologies() }
      </td>
      <td className={ style.medium }>
        { getDate(report?.date) }
      </td>
      <td>
        { renderText(report?.platform) }
      </td>
    </tr>
    <ReportDetails record={ report } expanded={ expanded }/>
  </>
  )
}

export default Report;
