import React from 'react';
import { Form, Select, Button, DatePicker } from 'antd';
import { Control, Controller } from 'react-hook-form';
import { Paper } from '@mui/material';
import moment from 'moment';

import { StatisticsFiltersType } from './../types';
import styles from './Filters.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

type Props = {
  filters: StatisticsFiltersType;
  defaultDates?: moment.MomentInput[];
  onSubmit: (e: React.BaseSyntheticEvent) => Promise<void>;
  control: Control;
};

const Filters: React.FC<Props> = ({ filters, defaultDates, onSubmit, control }) => {
  return (
    <Paper elevation={ 3 } classes={ { elevation3: styles.filters } }>
      <Form size='small'>
        <Form.Item
          key='dates'
          name='dates'
          label='dates'
          colon={ false }
          className={ styles.formItem }
          labelAlign='left'
        >
          <Controller
            as={
              <RangePicker
                allowClear={ false }
              />
            }
            name='dates'
            defaultValue={ defaultDates }
            control={ control }
          />
        </Form.Item>
        {Object.entries(filters).map(([ key, value ]) => value && value?.data && (
          <Form.Item
            key={ key }
            name={ key }
            label={ key }
            colon={ false }
            className={ styles.formItem }
            labelAlign='left'
          >
            <Controller
              as={
                <Select
                  placeholder={ key }
                  allowClear={ false }
                  { ...(value.type === 'multiple' ? { mode: 'multiple' } : {}) }
                  showArrow={ true }
                  className={ styles.select }
                >
                  { value?.data?.map((option) => (
                    <Option key={ option } value={ option }>{option}</Option>
                  ))}
                </Select>
              }
              name={ key }
              defaultValue={ value?.defaultValue }
              control={ control }
            />
          </Form.Item>
        ))}
        <Button
          type="primary"
          htmlType="submit"
          onClick={ onSubmit }
          className={ styles.button }
        >
          Filter
        </Button>
      </Form>
    </Paper>
  );
};

export default Filters;
