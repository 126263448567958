import React from 'react'
import style from './AssociatedProjects.module.scss'
import { AssociatedCompanyProjectType } from './../../../graphql/codegen/graphql'
import { EnvironmentOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
const { TabPane } = Tabs;

type Props = {
  projects: AssociatedCompanyProjectType[];
}

const AssociatedProjects: React.FC<Props> = ({ projects }) => {
  return (
    <Tabs defaultActiveKey="1" tabPosition="left">
      {projects && projects.map((project, index)=> (
        <TabPane
          tab={ project.ourCompanyProject?.name }
          key={ ''+index }
          className={ style.content }>
          <h4 >{project.ourCompanyProject?.city+', ' +
          project.ourCompanyProject?.country}
          </h4>

          <h4 >
            <a href={ 'http://maps.google.com/?q=' +
            project.ourCompanyProject?.lat +',' + project.ourCompanyProject?.lon }
            target="_blank" rel="noopener noreferrer">
              <EnvironmentOutlined />see on map</a>
          </h4>

        </TabPane>
      ))}
    </Tabs>
  )
}

export default AssociatedProjects
