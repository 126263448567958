import { GlobalPreferenceModelType } from '../../../graphql/codegen/graphql';
import React from 'react';
import { DatePicker, Form, Input, InputNumber, Switch } from 'antd';
import moment from 'moment';

type Props = {
    setting: GlobalPreferenceModelType;
    customElement: JSX.Element | null;
}

const ScraperSettingField: React.FC<Props> = ({ setting, customElement }) => {
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  const extractName = (rawName: string): string => {
    return rawName.split('_').join(' ');
  }

  let input = null;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const fieldOptions: any = {
    label: extractName( setting.name ),
    name: setting.name,
    extra: setting.helpText,
    rules: [
      {
        required: true,
        message: 'This field is required',
      },
    ],
  }

  switch (setting.fieldType) {
    case 'integer':
      input = <InputNumber/>
      fieldOptions.initialValue = parseInt(setting?.rawValue || '0');
      break;
    case 'boolean':
      input = <Switch />
      fieldOptions.initialValue = setting?.rawValue?.toLowerCase() === 'true';
      fieldOptions.valuePropName = 'checked';
      break;
    case 'date':
      input = <DatePicker />
      fieldOptions.initialValue = moment(setting?.rawValue);
      break;
    case 'datetime':
      input = <DatePicker showTime={ true } />
      fieldOptions.initialValue = moment(setting?.rawValue);
      break;
    default:
    case 'string':
      input = <Input type={ 'text' } />
      fieldOptions.initialValue = setting?.rawValue || '';
      break;
  }

  return (
    <Form.Item
      { ...formItemLayout }
      { ...fieldOptions }
    >
      { customElement || input }
    </Form.Item>
  )
}

export default ScraperSettingField;
