import React, { useState } from 'react'
import style from './SkillFilters.module.scss'
import { Select, Divider, Input, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client'
import { getSkillFilter } from './../../../graphql/queries/scrapers'
import { updateSkillFilter, createSkillFilter } from './../../../graphql/mutations/scrapers'

import { GetSkillFilterQuery, GetSkillFilterQueryVariables,
  UpdateSkillFilterMutation, UpdateSkillFilterMutationVariables,
  CreateSkillFilterMutation, CreateSkillFilterMutationVariables,
  ScraperStatusType,
} from './../../../graphql/codegen/graphql'
import { SelectValue } from 'antd/lib/select';
const { Option } = Select;

type Props = {
  scraper: ScraperStatusType;
  refetchScrapers: () => void;
}

const SkillFilters: React.FC<Props> = ({ scraper, refetchScrapers }) => {
  const { loading: loadingAllFilters, data: allFilters, refetch: refetchFilters } =
    useQuery<GetSkillFilterQuery, GetSkillFilterQueryVariables>(getSkillFilter)

  const [ updateSkillFilterMutation ] = useMutation<
    UpdateSkillFilterMutation, UpdateSkillFilterMutationVariables>(updateSkillFilter);
  const [ createSkillFilterMutation ] = useMutation<
    CreateSkillFilterMutation, CreateSkillFilterMutationVariables>(createSkillFilter);

  const handleChange = (value: SelectValue, add: boolean): void => {
    updateSkillFilterMutation({ variables: { id: +value,
      [ scraper.skillFilterScraperName as string ]: add } })
        .then((res) => {
          if (res?.data?.updateSkillFilter?.ok) {
            refetchScrapers()
            refetchFilters()
            add ?
              notification.success({ message: 'Filter succesfully added' }) :
              notification.success({ message: 'Filter succesfully removed' });
          } else {
            notification.error({ message: 'No filter changed' });
          }
        })
  }

  const [ newFilter, setNewFilter ] = useState('');
  const onAddFilter = (): void => {
    createSkillFilterMutation({ variables: {
      skill: newFilter,
      [ scraper.skillFilterScraperName as string ]: true } })
        .then(async (res) => {
          if (res?.data?.createSkillFilter?.ok) {
            notification.success({ message: 'Filter succesfully added' })
            refetchScrapers()
            refetchFilters()
          } else {
            notification.error({ message: 'No filter added' });
          }
          setNewFilter('')
        })
        .catch((e) => {
          e.graphQLErrors[ 0 ].message.includes('duplicate key value violates unique constraint') &&
          notification.error({ message: 'Skill with this name already exists' });
        })
  }

  return (
    <div className={ style.filtersWrapper }>
      <div className={ style.title }>
        Skill filters
      </div>
      <div className={ style.filters }>
        {!loadingAllFilters && (
          <Select
            value={ scraper?.activeSkillsFilter?.map((filter) => filter?.id as string) }
            mode="multiple"
            className={ style.select }
            onSelect={ (value: SelectValue): void => handleChange(value, true) }
            onDeselect={ (value: SelectValue): void => handleChange(value, false) }
            showArrow
            showSearch={ false }
            dropdownRender={ (menu): JSX.Element => (
              <div>
                {menu}
                <Divider style={ { margin: '4px 0' } } />
                <div className={ style.inputWrapper }>
                  <Input
                    allowClear
                    className={ style.input }
                    value={ newFilter }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>): void=>
                      setNewFilter(e.target.value) }
                    onPressEnter={ (e: React.KeyboardEvent<HTMLInputElement>): void=> {
                      e.stopPropagation();
                      onAddFilter()
                    } }
                  />
                  <div className={ style.inputBtn } onClick={ onAddFilter }>
                    <PlusOutlined /> Add filter
                  </div>
                </div>
              </div>
            ) }
          >
            {
              allFilters?.skillFilter?.map((filter) => (
                <Option key={ filter?.id } value={ filter?.id || '' }>{filter?.skill}</Option>))
            }
          </Select>
        )}
      </div>
    </div>
  )
}

export default SkillFilters
