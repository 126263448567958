import React from 'react';
import { Tag } from 'antd';
import style from './ReportDetails.module.scss';
import { LinkedInReportType } from './../../../graphql/codegen/graphql'
import { formatUrl } from '../../../utils/helpers'
import AssociatedProjects from './../AssociatedProjects'

type Props = {
  record: LinkedInReportType;
  expanded: boolean;
}

const ReportDetails: React.FC<Props> = ({ record, expanded }) => (
  <tr><td colSpan={ 13 } >
    <div className={ expanded ? style.expandedRecord : style.hidden }>
      <div className={ style.column }>
        <span className={ style.title }>Contact</span>
        <div className={ style.row }>
          <span className={ style.label }>Company Link</span>
          <span className={ style.content }>
            <a href={ record?.company?.url || '' } target="_blank" rel="noopener noreferrer">
              { formatUrl(record?.company?.url) }
            </a>
          </span>
        </div>
        <div className={ style.row }>
          <span className={ style.label }>Job Link</span>
          <span className={ style.content }>
            <a href={ record?.jobLink || '' } target="_blank" rel="noopener noreferrer">
              { formatUrl(record?.jobLink) }
            </a>
          </span>
        </div>
        <div className={ style.row }>
          <span className={ style.label }>Linkedin Link</span>
          <span className={ style.content }>
            <a href={ record?.person?.linkedin || '' } target="_blank" rel="noopener noreferrer">
              { formatUrl(record?.person?.linkedin) }
            </a>
          </span>
        </div>
      </div>
      <div className={ style.column }>
        <span className={ style.title }>Details</span>
        <div className={ style.row }>
          <span className={ style.label }>All technologies</span>
          <span className={ style.content }>{record?.person?.wantedSkills?.map((tag, index) => (
            <Tag color="default" key={ index }>
              {tag}
            </Tag>
          ))}</span>
        </div>
        <div className={ style.row }>
          <span className={ style.label }>Employees</span>
          <span className={ style.content }>{record?.company?.employees}</span>
        </div>
        <div className={ style.row }>
          <span className={ style.label }>Branches</span>
          <span className={ style.content }>{record?.company?.branches}</span>
        </div>
        <div className={ style.row }>
          <span className={ style.label }>cbRank</span>
          <span className={ style.content }>{record?.company?.cbRank}</span>
        </div>
      </div>
      <div className={ style.column }>
        <AssociatedProjects projects={ record?.associatedcompanyprojectSet }/>
      </div>
    </div>
  </td>
  </tr>
)

export default ReportDetails;
