import React, { ReactChild } from 'react'
import style from './Backdrop.module.scss'

type Props = {
  visible: boolean;
  handleVisible: () => void;
  children: ReactChild;
}

const Backdrop: React.FC<Props> = ({ visible, children, handleVisible }) => (
  <div
    className={ [ style.backdrop, !visible && style.hidden ].join(' ') }
    onClick={ handleVisible } >
    {children}
  </div>
)

export default Backdrop
