import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Spin } from 'antd';
import style from './Scrapers.module.scss';
import Scraper from './Scraper';
import { ScraperStatusType, GetScrapersQuery } from './../../graphql/codegen/graphql'
import { getScrapers } from '../../graphql/queries/scrapers';
import ScrapersLogs from './ScrapersLogs'
import AvailableTasks from './AvailableTasks/AvailableTasks';
import UpdateProspects from './UpdateProspects';
import VerificationCodeInput from './VerificationCodeInput/VerificationCodeInput';

const Scrapers: React.FC = () => {
  const [ shouldFetchMoreLogs, setShouldFetchMoreLogs ] = useState(false)
  const { loading, data, error, refetch } =
    useQuery<GetScrapersQuery>(getScrapers);

  const refetchScrapers = (): void => {
    refetch()
  }
  const onHitBottom = ({ top }: {top: number}): void =>
    (top > .99) ? setShouldFetchMoreLogs(true) : setShouldFetchMoreLogs(false);

  return (
    <Scrollbars
      hideTracksWhenNotNeeded className={ style.container }
      onScrollFrame={ onHitBottom }>
      <Spin
        spinning={ loading || error != null }
        tip="Loading..." delay={ 1000 } size="large"
        wrapperClassName={ style.spinContainer }>
        <div className={ style.card }>

          <div className={ style.scrapers }>
            {!loading && !error &&
            (data?.scraperStatus as ScraperStatusType[]).map(
                (scraper) =><Scraper
                  key={ scraper.id }
                  scraper={ scraper }
                  refetchScrapers={ refetchScrapers }/>)}
          </div>
        </div>
        <div className={ style.card }>
          { !loading && <VerificationCodeInput /> }
        </div>
        <div className={ style.card }>
          { !loading && <AvailableTasks /> }
        </div>
        <div className={ style.card }>
          {
            !loading && <UpdateProspects />
          }
        </div>
        <div className={ style.card }>
          { !loading && <ScrapersLogs
            shouldFetchMoreLogs={ shouldFetchMoreLogs }
            scrapers={ data?.scraperStatus?.map((scraper) => scraper?.skillFilterScraperName) as string[] }/> }
        </div>
      </Spin>
    </Scrollbars>
  );
};

export default Scrapers;
