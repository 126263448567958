import React, { ReactElement } from 'react'
import style from './Logo.module.scss'
import logo from './../../../assets/images/ProfilSoftware_logo_nopadding.svg'

const Logo: React.FC = (): ReactElement =>
  <div className={ style.logo }>
    <img src={ logo } alt="Logo" />
  </div>

export default Logo
