import React, { RefObject, useRef, useState } from 'react'
import { Tabs, Form, Button, DatePicker, Select, Space } from 'antd'
import ScraperLogs from './../ScraperLogs'
import style from './ScrapersLogs.module.scss'
import { FormInstance } from 'antd/lib/form';
import { FormLabelAlign, Store } from 'antd/lib/form/interface';
import moment from 'moment';

const { TabPane } = Tabs
const { Option } = Select;

type Props = {
  scrapers: string[];
  shouldFetchMoreLogs: boolean;
}

type FilterConfig = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  logLevelName: string | null;
}

const ScrapersLogs: React.FC<Props> = ({ scrapers, shouldFetchMoreLogs }) => {
  const LOG_LEVELS = [ 'INFO', 'WARNING', 'ERROR' ];
  const formLayout = {
    labelAlign: 'left' as FormLabelAlign,
    labelCol: { span: 1 },
    wrapperCol: { span: 2 },
  };
  const refForm = useRef() as RefObject<FormInstance>
  const initialFilterValues = { startDate: null, endDate: null, logLevelName: null };
  const [ filters, setFilters ] = useState<FilterConfig>(initialFilterValues);

  const handleFormSubmit = (values: Store): void => {
    setFilters({
      startDate: values.startDate,
      endDate: values.endDate,
      logLevelName: values.logLevelName,
    });
  }

  const handleClearForm = (): void => {
    (refForm?.current as FormInstance).resetFields()
    setFilters(initialFilterValues);
  }

  return <div className={ style.scrapersLogs }>
    <h3 className={ style.header }>Scrapers logs</h3>
    <Form ref={ refForm } onFinish={ handleFormSubmit } size='small' { ...formLayout } >
      <Form.Item
        key={ 'startDate' }
        name={ 'startDate' }
        label={ 'Start date' }
        colon={ false }>
        <DatePicker/>
      </Form.Item>
      <Form.Item
        key={ 'endDate' }
        name={ 'endDate' }
        label={ 'End date' }
        colon={ false }>
        <DatePicker/>
      </Form.Item>
      <Form.Item
        key={ 'logLevelName' }
        name={ 'logLevelName' }
        label={ 'Log level' }
        colon={ false }>
        <Select allowClear={ true }>
          {LOG_LEVELS.map((option) => (
            <Option
              key={ option }
              value={ option }>
              {option}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Space>
        <Button htmlType={ 'submit' } type={ 'primary' }>Filter</Button>
        <Button onClick={ handleClearForm } htmlType={ 'button' } type={ 'primary' }>Clear</Button>
      </Space>
    </Form>

    <Tabs
      defaultActiveKey="1"
      size="large"
    >
      {scrapers.map((name) => (
        <TabPane tab={ name } key={ name }>
          <ScraperLogs
            startDate={ filters.startDate }
            endDate={ filters.endDate }
            logLevelName={ filters.logLevelName }
            shouldFetchMoreLogs={ shouldFetchMoreLogs }
            scraperName={ name }/>
        </TabPane>
      ))}
      <TabPane tab={ 'lead_updater' }>
        <ScraperLogs
          startDate={ filters.startDate }
          endDate={ filters.endDate }
          logLevelName={ filters.logLevelName }
          shouldFetchMoreLogs={ shouldFetchMoreLogs }
          scraperName={ 'linkedin_update' }/>
      </TabPane>
    </Tabs>
  </div>
}

export default ScrapersLogs
