import { gql } from '../codegen'

export const getStatisticsFilters = gql(`
query getStatisticsFilters {
  statisticsFilters {
    frequency {
      type
      data
    }
    tags{
      type
      data
    }
    scrapers{
      type
      data
    }
    statuses{
      type
      data
    }
  }
}
`)

export const getStatistics = gql(`
query getStatistics(
  $startDate: Date
  $endDate: Date
  $frequency: String
  $tags: [String]
  $statuses: [String]
  $scrapersName: [String]
) {
  statistics(
    startDate: $startDate
    endDate: $endDate
    frequency: $frequency
    tags: $tags
    statuses: $statuses
    scrapersName: $scrapersName
  ) {
    scrapped {
      scraperName,
      sum
      statuses {
        statusName
        sum
        data {
          dateTime
          total
        }
      }
      tags {
        tagName
        sum
      }
      data {
        dateTime
        total
      }
    }
  }
}
`)
