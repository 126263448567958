import React, { ReactNode } from 'react';
import style from './UpdateProspects.module.scss';
import { useQuery, useMutation } from '@apollo/client';
import { Spin, Form, Select, Button, notification, Input, Space, Tooltip, Popconfirm } from 'antd';
import { getAvailableWoodpeckerFields } from '../../../graphql/queries/scrapers';
import { changeWoodpeckerProspects } from '../../../graphql/mutations/scrapers';
import {
  // eslint-disable-next-line camelcase
  GetAvailableWoodpeckerFieldsQuery, ChangeWoodpeckerProspects, RootMutationChangeWoodpeckerProspectsArgs, FieldToSearchBy,
} from '../../../graphql/codegen/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';

const { Option } = Select;

type Props = {
    updateField: (value: string) => void;
    header: string;
    listOfFieldsToSearchBy: Maybe<string>[];
}

type FieldSetProps = {
    selectFields: Maybe<string>[];
    updateSelected: (value: Map<number, string>) => void;
    updateInputs: (value: Map<number, string>) => void;
}

const FieldToSearchBySelector: React.FC<Props> = ({ updateField, header, listOfFieldsToSearchBy }) => {
  return (
    <Form>
      <h4>{header}</h4>
      <p>Choose field to perform search for prospects to update</p>
      <Form.Item
        name={ 'fieldToSearchBy' }
      >
        <Select onSelect={ (value): void => updateField(value.toString()) }>
          {listOfFieldsToSearchBy.map((v) => !v ? null : <Option key={ v } value={ v }>
            {v}
          </Option>)}
        </Select>
      </Form.Item>
    </Form>
  )
}

const DynamicFieldSet: React.FC<FieldSetProps> = ({ selectFields, updateSelected, updateInputs }) => {
  const [ WoodpeckerFields, setWoodpeckerFields ] = React.useState<Maybe<string>[]>();
  const [ selectedFields, setSelectedFields ] = React.useState<Map<number, string>>(new Map())
  const [ inputValues, setInputValues ] = React.useState<Map<number, string>>(new Map())

  React.useEffect(() => {
    setWoodpeckerFields(selectFields);
  }, [ selectFields ]);

  const updateInputValues = (key: number, value: string): void => {
    const updatedInputValues = new Map<number, string>(inputValues);
    updatedInputValues.set(key, value);
    setInputValues(updatedInputValues);
    updateInputs(updatedInputValues)
  }

  const updateSelectedFields = (key: number, value: string): void => {
    const updatedSelectedFields = new Map<number, string>(selectedFields);
    updatedSelectedFields.set(key, value);
    setSelectedFields(updatedSelectedFields);
    updateSelected(updatedSelectedFields)
  }

  const deleteFromSelectedFields = (field: FormListFieldData): void => {
    const updatedSelectedMap = new Map<number, string>(selectedFields);
    updatedSelectedMap.delete(field.key);
    setSelectedFields(updatedSelectedMap);
    updateSelected(updatedSelectedMap)
  }
  const removeFormField = (removeOperation: (n: number | number[]) => void, field: FormListFieldData): void => {
    removeOperation(field.name)
    deleteFromSelectedFields(field)
  }

  return (
    <Form name="dynamic_form_item">
      <Form.List
        name="names"
      >
        {/* eslint-disable-next-line max-len */}
        {(fields: FormListFieldData[], { add, remove }: FormListOperation, { errors }: { errors: ReactNode[]}): JSX.Element => (
          <>
            {fields.map((field) => (
              <Form.Item
                label={ '' }
                required={ false }
                key={ field.key }
              >
                <Form.Item
                  { ...field }
                  noStyle
                >
                  <Input.Group compact>
                    <Select style={ { width: '45%' } } onSelect={ (value): void => {
                      updateSelectedFields(field.key, value.toString())
                      updateInputValues(field.key, '')
                    } }>
                      {(WoodpeckerFields as string[]).map((value: string) => !value ? null :
                      <Option key={ value } value={ value }
                        disabled={ Array.from(selectedFields.values()).includes(value) }>
                        {value}
                      </Option>)
                      }
                    </Select>
                    <Input disabled={ !selectedFields.get(field.key) } maxLength={ 100 } type={ 'text' }
                      onBlur={ (event): void => {
                        updateInputValues(field.key, event.currentTarget.value)
                      }
                      } name={ field.name.toString() } placeholder="Field value"
                      style={ { width: '45%' } }/>

                  </Input.Group>

                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className={ style.dynamicDeleteButton }
                    onClick={ (): void => {
                      removeFormField(remove, field)
                    } }
                  />) : null}
              </Form.Item>
            ))}
            <Form.Item>
              {fields.length < selectFields.length ? (
                <Button
                  type="dashed"
                  onClick={ (): void => {
                    add();
                  } }
                  style={ { width: '100%' } }
                  icon={ <PlusOutlined/> }
                >
                  Add
                </Button>
                            ) : null}
              <Form.ErrorList errors={ errors }/>
            </Form.Item>
          </>

        )}
      </Form.List>
    </Form>
  );
};

const UpdateProspects: React.FC = () => {
  const { loading: isLoadingFields, data: listOfFieldsToSearchBy } =
        useQuery<GetAvailableWoodpeckerFieldsQuery>(getAvailableWoodpeckerFields);

  const [
    executeUpdateProspects,
    { loading: isWaitingForUpdatingProspects },
  ] = useMutation<ChangeWoodpeckerProspects, RootMutationChangeWoodpeckerProspectsArgs>(changeWoodpeckerProspects);

  const handle = (msg: string): void => {
    notification.success({
      message: msg,
    });
  }
  const handleError = (msg: string): void => {
    notification.error({
      message: msg,
    });
  }

  const [ fieldToSearchBy, setFieldToSearchBy ] = React.useState<string>()
  const [ valueToSearchBy, setValueToSearchBy ] = React.useState<string>()
  const [ selected, setSelected ] = React.useState<Map<number, string>>()
  const [ inputValues, setInputValues ] = React.useState<Map<number, string>>()

  const updateField = (fieldValue: string): void => {
    setFieldToSearchBy(fieldValue)
  }

  const updateSelected = (newSelected: Map<number, string>): void => {
    setSelected(newSelected)
  }

  const updateInputValues = (newInputValues: Map<number, string>): void => {
    setInputValues(newInputValues)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getUpdateValuesForWoodpeckerProspects = (): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = {}
    selected?.forEach((selectedValue: string, selectedKey: number) => {
      inputValues?.forEach((inputValue: string, inputKey: number) => {
        if (selectedKey === inputKey) {
          result[ selectedValue ] = inputValue
        }
      })
    })
    return result
  }

  const handleUpdateProspects = (): void => {
    const updateValues = getUpdateValuesForWoodpeckerProspects()
    executeUpdateProspects({
      variables: {
        // eslint-disable-next-line camelcase
        fieldToSearchBy: fieldToSearchBy as Required<FieldToSearchBy>,
        valueToSearchBy: valueToSearchBy as Required<string>,
        updateValues: updateValues,
      },
    }).then(() => handle('Woodpecker prospects update task executed successfully'))
        .catch((err) => handleError(err.message));
  }

  return (
    <Spin
      spinning={ isLoadingFields || isWaitingForUpdatingProspects }
      tip={ 'Loading...' } delay={ 100 } size={ 'large' }
    >
      <section className={ style.updateProspects }>
        <h3>Update Woodpecker prospects</h3>
        <Space direction={ 'vertical' }>
          <FieldToSearchBySelector
            updateField={ updateField }
            header={ 'Select field to search by' }
            listOfFieldsToSearchBy={ listOfFieldsToSearchBy?.__type?.enumValues?.map((value) => value?.name) || [] }
          />
          <p>Choose prospects with that value on selected field
            <Tooltip className={ style.tooltip } title='For example, if you select the "city" field and enter
              "London", all prospects with "London" value in "city" field will be selected for updating'>
              <QuestionCircleOutlined />
            </Tooltip>
          </p>
          <Input
            onChange={ (event): void => setValueToSearchBy(event.currentTarget.value) }
            name={ 'search' }
            disabled={ !fieldToSearchBy }
            placeholder="Search value"
            className={ style.searchValue }
            allowClear
          />
          <p> Insert value to update on selected fields
            <Tooltip
              className={ style.tooltip }
              title='Given values will replace those contained on chosen fields in the prospects selected for update'>
              <QuestionCircleOutlined />
            </Tooltip></p>
          <DynamicFieldSet
            selectFields={ listOfFieldsToSearchBy?.__type?.enumValues?.map((value) => value?.name) || [] }
            updateSelected={ updateSelected }
            updateInputs={ updateInputValues }

          />
          <Popconfirm
            title={ 'Update Woodpecker prospects?' }
            onConfirm={ handleUpdateProspects }
            okText="Update"
            cancelText="Cancel"
            placement="bottomLeft"
          >
            <Button disabled={ !fieldToSearchBy || !selected || !valueToSearchBy } type="primary"
              htmlType="submit" onClick={
                (event): void => {
                  event.preventDefault()
                }
              }>
              Update
            </Button>
          </Popconfirm>
        </Space>
      </section>
    </Spin>
  )
}

export default UpdateProspects
