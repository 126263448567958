import moment from 'moment';

export const formatDateFromString = (text: string): string =>
  moment(text).format('DD MMM YYYY');

export const formatDateFromStringLogs = (text: string): string =>
  moment(text).format('DD MMM YYYY HH:mm');

export const dateByStringSorter = (a: {date: string | null}, b: {date: string|null}): number => {
  const dateA = moment(a.date).valueOf();
  const dateB = moment(b.date).valueOf();
  return !dateA ? 1 : !dateB ? -1 : (+dateB) - (+dateA);
};

export const formatUrl = (value: string | null | undefined): string => {
  return value ?
    value.trim().replace(/^(http:\/\/www.)|(https:\/\/www.)|(http:\/\/)|(https:\/\/)|(www.)/, '') :
    '';
};

const chartColors = {
  blue: '#4c6cb4',
  red: '#c43b59',
  orange: '#ffa64d',
  lightGreen: '#28c195',
  violet: '#8566b7',
  lightBlue: '#abbbdb',
  lightRed: '#ec8291',
  green: '#2c837f',
};

export const getColors = (
    length: number,
    colors: string[] = Object.values(chartColors),
): string[] => {
  if (colors.length < length) {
    const missing = length - colors.length;
    let newColors = colors;
    for (let i = 0; i < missing; i++) {
      const color = Math.floor(Math.random() * (270 - 180 + 1)) + 180;
      const saturation = Math.floor(Math.random() * (50 - 40 + 1)) + 40;
      const lightness = Math.floor(Math.random() * (65 - 55 + 1)) + 55;
      newColors = [
        ...newColors,
        'hsl('+ color + ','+ saturation + '%,' + lightness+ '%)',
      ];
    }
    return newColors;
  } else {
    return colors;
  }
};
