import { gql } from '@apollo/client';

export const GET_REPORT_FILTERS = gql`
 query  {
    reportFilters @client {
      platform
      startDate
      endDate
      status
      employees
      category
      location
      country
      search
      withoutEmail
      ids
    }
  }
`;

export const GET_SORT_CONFIG = gql`
query {
  sortConfig @client {
    orderBy
    orderDirection
  }
}
`

export const GET_SELECTED = gql`
  query {
    selected @client
  }
`
